<section class="section-alert">
  <div class="alert alert-info" role="alert" *ngIf="!isAuthorized">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      {{'youNeedLoginToUsePlatform' | translate}}
    </div>
    <div class="alert-button">
      <button mat-raised-button color="primary" (click)="navigateToLogin()">
        {{'login' | translate}}
      </button>
    </div>
  </div>

  <div class="alert alert-info" role="alert" *ngIf="isAuthorized && !kycConfirmed">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      {{'toJoinDealsNeedPassVerification' | translate}}
    </div>
    <div class="alert-button">
      <button mat-raised-button color="primary" (click)="navigateToKYC()">
        {{'kycForBullStarterProjects' | translate}}
      </button>
    </div>
  </div>
</section>
