import { Component, Input, OnInit,  OnDestroy } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { BigNumber } from "bignumber.js";
import { DealDTO, DealServiceProxy } from '../../service-proxies/service-proxies';
import { Router } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'deal-viewer',
  templateUrl: './deal-viewer.component.html',
  styleUrls: ['./deal-viewer.component.scss'],
})

export class DealViewerComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private web3Service: Web3Service,
    private router: Router,
    public translate: TranslateService
  ) {
    super();
  }

  @Input() item: DealDTO;

  timerViewDays: number;
  timerViewHours: number;
  timerViewMin: number;
  timerViewSec: number;
  updateTimeTimerId: NodeJS.Timeout;
  tokensForDistribution: string;

  public nowTimeStamp: number = Math.floor(Date.now() / 1000);

  public get isUpcoming(): boolean {
    return this.nowTimeStamp < this.item.startTime;
  }

  public get isStarted(): boolean {
    return this.nowTimeStamp > this.item.startTime && this.nowTimeStamp < this.item.finishTime;
  }

  public get isFinished(): boolean {
    return this.nowTimeStamp > this.item.finishTime;
  }


  public get getDistributedPercent(): number {
    if (this.item.maxDistributedTokenAmount && this.tokensForDistribution)
      return new BigNumber(this.tokensForDistribution).div(this.item.maxDistributedTokenAmount).multipliedBy(100).toNumber();
    return 0;
  }

  async ngOnInit() {
    this.updateTimeTimerId = setInterval(() => {
      this.nowTimeStamp = Math.floor(Date.now() / 1000);

      var diffStart = 0
      if (this.isUpcoming)
        var diffStart = this.item.startTime - this.nowTimeStamp;
      else if (this.isStarted)
        var diffStart = this.item.finishTime - this.nowTimeStamp;
      if (diffStart > 0) {
        this.timerViewDays = Math.floor(diffStart / (3600 * 24));
        this.timerViewHours = Math.floor(diffStart % (3600 * 24) / 3600);
        this.timerViewMin = Math.floor(diffStart % 3600 / 60);
        this.timerViewSec = Math.floor(diffStart % 60);
      }
      else {
        this.timerViewDays = 0;
        this.timerViewHours = 0;
        this.timerViewMin = 0;
        this.timerViewSec = 0;
      }
    }, 1000);

    this.web3Service.getDealTokensForDistribution(this.item.dealAddress).then((resp) => {
      this.tokensForDistribution = resp;
    });
  }

  async ngOnDestroy() {
    if (this.updateTimeTimerId) {
      clearInterval(this.updateTimeTimerId);
    }
  }

  public navigateToDeal() {
    ///deal-detail?address='+ item.dealAddress
    this.router.navigate(['/deal-detail'], { queryParams: { address: this.item.dealAddress } });
  }
  public addToGoogleCalendar() {
    const startDate = new Date(this.item.startTime * 1000).toISOString().replace(/-|:|\.\d\d\d/g,'');
    const finishDate = new Date(this.item.finishTime * 1000).toISOString().replace(/-|:|\.\d\d\d/g,'');
    const url = new URL('https://www.google.com/calendar/render');
    const eventInfo = 'Deal details: <a href="https://app.bullperks.com/deal-detail?address=' + this.item.dealAddress + '">' +
      'https://app.bullperks.com/deal-detail?address=' + this.item.dealAddress + '</a>';
    url.searchParams.append('action', 'TEMPLATE');
    url.searchParams.append('text', this.item.name + ' Deal on BullPerks');
    url.searchParams.append('dates', startDate + '/' + finishDate);
    url.searchParams.append('details', eventInfo);
    url.searchParams.append('sf', 'true');
    url.searchParams.append('output', 'xml');

    window.open(url.toString(), '_blank').focus();
  }

  public getTotalRaiseETH(deal: DealDTO): number {
    return new BigNumber(deal.maxDistributedTokenAmount).shiftedBy(-deal.rewardDecimal).multipliedBy(deal.tokenPrice).shiftedBy(-deal.paymentDecimal).toNumber();
  }

  public getRatioPer1ETH(deal: DealDTO): number {
    return new BigNumber(1).shiftedBy(deal.paymentDecimal).dividedBy(deal.tokenPrice).toNumber()
  }

  getTranslatedDealTypeName(value: number): string {
    if (value == 0)
      return this.translate.instant('vc')
    else if (value == 1)
      return this.translate.instant('ido')
    else if (value == 2)
      return this.translate.instant('ino')
    else if (value == 3)
      return this.translate.instant('equity')
    return value.toString();
  }

  get firstTokenMaxDistributedTokenAmount(){
    if(this.item.customPrice){
      return (Number(this.item.maxDistributedTokenAmount) * Number(this.item.investmentPercentage) / Number(100) / Number(this.item.customPrice) * Number(this.item.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return this.item.maxDistributedTokenAmount;
  }

  get secondTokenMaxDistributedTokenAmount(){
    if(this.item.secondTokenCustomPrice){
      return (Number(this.item.maxDistributedTokenAmount) * Number(this.item.secondTokenInvestmentPercentage) / Number(100) / Number(this.item.secondTokenCustomPrice) * Number(this.item.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return this.item.maxDistributedTokenAmount;
  }

}
