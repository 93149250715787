<main class="dashboard-main">
  <section class="dashboard-section p-40">
    <div class="white-card p-4 sign-up-form text-center">

      <i class="icomoon-Mail1 __icon"></i>
      <div class="small-text my-3 mute-80">Confirm Wallet Change</div>

      <button type="button"
              class="w-100"
              mat-raised-button
              color="primary"
              (click)="confirmWalletChange()"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="waiting">
        Confirm
      </button>
    </div>
  </section>
</main>
