import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Web3Service } from '../../shared/web3-service';
import swal from "sweetalert2";
import {ChangeWalletServiceProxy} from "../../service-proxies/service-proxies";
import {Router} from "@angular/router";


@Component({
  templateUrl: './dlg-wallet-transfer.component.html',
  styleUrls: ['./dlg-wallet-transfer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DlgWalletTransferComponent implements OnInit {
  web3ChainId: number;
  newWalletAddress: string;
  isError: boolean
  errorMessage: string
  constructor(
    public dialogRef: MatDialogRef<DlgWalletTransferComponent>,
    private userSessionProvider: UserSessionProvider,
    private web3Service: Web3Service,
    private dialog: MatDialog,
    private changeWalletService: ChangeWalletServiceProxy
  ) {
    this.web3ChainId = web3Service.chainIdNumber;
  }

  initiateWalletChange() {
    this.changeWalletService.initiateWalletChange(this.userSessionProvider.linkedWallet, this.newWalletAddress, 'bullperks').subscribe(
      (res: any) => {
        this.dialogRef.close();
        this.showRequestSent();
        this.isError = false;
      },
      (error: any) => {
        this.isError = true;
        this.errorMessage = JSON.parse(error.message).message;
      }
    );
  }

  showRequestSent() {
    swal.fire({
      title: 'Request Created',
      text: 'Please check your email for a confirmation link. The link will expire in 24 hours.',
      icon: "success",
      confirmButtonText: `<div class="d-flex align-items-center g-1"><i class="icon icomoon-checkmark me-1"></i> Got it</div>`
    })
  }

  showRequestPending() {
    swal.fire({
      title: 'Change Wallet',
      html: `<span style="color: red">You have a pending request. Please confirm your request via email or try again in 24 hours.</span>`,
      icon: "warning",
      confirmButtonText: `<div style="display: flex; align-items: center"><i class="icon icomoon-checkmark"></i> Got it</div>`
    });
  }

  account: string;

  ngOnInit(): void {
    this.account = this.userSessionProvider.linkedWallet;
  }
}
