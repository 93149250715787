import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog  } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StakingPoolDTO, StakingPoolServiceProxy, UsersServiceProxy } from '../../service-proxies/service-proxies';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Web3Service } from '../../shared/web3-service';
import { BigNumber } from "bignumber.js";
import { DlgLockerPoolComponent } from '../dlg-locker-pool';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { KYC_required } from 'src/internal/kyc.decorator';
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: './pool-detail.component.html',
  styleUrls: ['./pool-detail.component.scss']
})
export class PoolDetailComponent extends ComponentBase implements OnInit, OnDestroy {

  constructor(
    private _dialog: MatLegacyDialog,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private route: ActivatedRoute,
    private router: Router,
    private stakingPoolService: StakingPoolServiceProxy,
    private usersService: UsersServiceProxy,
    public translate: TranslateService
  ) {
    console.log('PoolDetailComponent')
      super();
    }

  web3ChainId: number;
  account: string;
  stakingPoolAddress: string;
  item: StakingPoolDTO;
  waiting: boolean;
  step: number = 1;
  tokenApproved: boolean;
  balance: number;

  poolName: string;
  poolDescription: string;

  currentStakeAmount: string = "0";
  currentPendingReward: string = "0";
  staked: boolean;

  allPenalties: any[] = new Array();
  userLockingStartTimestamp: number;
  penaltyBP: number = 0;
  amountToDeposit: number = 0;
  allStakeAmount = '0';
  WETHaddress: string;
  apy: number;

  async ngOnInit() {
    await this.web3Service.initWeb3();

    this.route
      .queryParams
      .subscribe(params => {
        this.stakingPoolAddress = params['address'];
        console.log(`item address: ${this.stakingPoolAddress}`);
        this.stakingPoolService.getByAddress(this.stakingPoolAddress).subscribe(async (result) => {
            this.item = result;
            console.log('item', this.item);
            this.getUserStake();
            await this.getBalance();
            await this.getWETHaddress();
            await this.getAPY();
            await this.updateContractData();
          },
          error => {
            this.processServiceError(error);
          });
      });

    if (this.userSessionProvider.linkedWallet) {
      await this.eventLogin(this.userSessionProvider.linkedWallet);
    }

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });
  }


  async eventLogin(username: string) {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
    this.getUserStake();
  }

  eventLogout(): void {
    console.log('signOut')
    this.account = "";
  }

  async getBalance(): Promise<void> {
    this.web3Service.GetTokenBalance(this.account, this.item.stakingToken).then((balance) => {
      this.balance = this.toNumberFromWei(balance, 18);
    });
  }

  async updateContractData() {
    for (let i = 0; i < 5; i++) {
      let penalties = await this.web3Service.getPoolPenalties(i, this.stakingPoolAddress);
      console.log('allPenalties', penalties);
      if(penalties != null)
        this.allPenalties.push(penalties);
    }
    this.userLockingStartTimestamp = parseInt(await this.web3Service.getUserLockingStart(this.account));
    await this.getPenaltyBP();
    this.currentPendingReward = await this.web3Service.getPoolPendingReward(this.stakingPoolAddress, this.account);
  }


  async getPenaltyBP(): Promise<void> {
    this.web3Service.getPoolPenaltyBP(this.item.startTime, this.item.poolAddress).then((penalty) => {
      console.log('getPoolPenaltyBP', penalty)
      if (penalty) {
        this.penaltyBP = penalty.toString();
      }
    });
  }

  getUserStake() {
    console.log(this.account + " - " + this.stakingPoolAddress);
    this.web3Service.getPoolUserInfo(
      this.account,
      this.stakingPoolAddress
    ).then((result) => {
      console.log("[TT] getUserStake", result);
      console.log(result[0]);
      this.currentStakeAmount = result[0];
      if(parseInt(this.currentStakeAmount) > 0) {
        this.staked = true;
      }
    });
  }

  public get getProgressPercent(): number {
    if(this.item == null)
      return 0;
    let currentTime = Math.floor(Date.now() / 1000);
    if(currentTime > this.item.startTime) {
      if(currentTime > this.item.finishTime) {
        return 100;
      }
      return new BigNumber(currentTime - this.item.startTime).div(this.item.finishTime - this.item.startTime).multipliedBy(100).toNumber();
    }
    return 0;
  }

  public get getCurrentPoolTokenAmount(): number {
    if(this.item == null)
      return 0;
    let bnPoolAmount = new BigNumber(this.item.poolTokenAmount).shiftedBy(-this.item.poolTokenDecimals).toNumber();
    let currentTime = Math.floor(Date.now() / 1000);
    if(currentTime > this.item.startTime) {
      if(currentTime > this.item.finishTime) {
        return bnPoolAmount;
      }
      return (currentTime - this.item.startTime) / (this.item.finishTime - this.item.startTime) * bnPoolAmount;
    }
    return 0;
  }

  @KYC_required
  async depositDialog() {
    let currentTime = Math.floor(Date.now() / 1000);
    if (currentTime > this.item.finishTime) {
      this.showWarningModal(this.translate.instant('thisStakingPoolIsClosedAndDoesNotSupportDeposits'));
      return;
    }
    if(this.item.hasWhitelisting) {
      let isWhitelisted = await this.web3Service.isWhitelisted(this.account, this.item.poolAddress);
      if(!isWhitelisted) {
        this.showErrorModal(this.translate.instant('youAreNotWhitelistedForThisPool'));
        return;
      }
    }
    console.log('open dialog');
    const dialogRef = this._dialog.open(DlgLockerPoolComponent, {
      panelClass: ['dlg-light', 'dlg-medium'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    console.log('currentStakeAmount', this.currentStakeAmount.toString(), this.item, this.account, this. amountToDeposit);
    
    dialogRef.disableClose = true;
    dialogRef.componentInstance.item = this.item;
    dialogRef.componentInstance.account = this.account;
    dialogRef.componentInstance.currentStakeAmount = this.currentStakeAmount;
    dialogRef.componentInstance.isDepositMode = true;
    dialogRef.componentInstance.depositAmount = this.amountToDeposit;

    dialogRef.afterClosed().subscribe(result => {
      this.getUserStake();
    });
  }

  @KYC_required
  async withdrawDialog() {
    const dialogRef = this._dialog.open(DlgLockerPoolComponent, {
      panelClass: ['dlg-light', 'dlg-medium'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    console.log('currentStakeAmount', this.currentStakeAmount);
    dialogRef.disableClose = true;
    dialogRef.componentInstance.item = this.item;
    dialogRef.componentInstance.account = this.account;
    dialogRef.componentInstance.currentStakeAmount = this.currentStakeAmount;
    dialogRef.componentInstance.isDepositMode = false;

    const maxWithdrawAmount = new BigNumber(this.currentStakeAmount).shiftedBy(-this.item.poolTokenDecimals).toNumber();
    console.log('maxWithdrawAmount', maxWithdrawAmount)
    if (this.amountToDeposit > maxWithdrawAmount) {
      dialogRef.componentInstance.withdrawAmount = maxWithdrawAmount;
    } else {
      dialogRef.componentInstance.withdrawAmount = this.amountToDeposit;
    }

    dialogRef.afterClosed().subscribe(result => {
      this.getUserStake();
    });
  }

  processServiceError(error: any) {
    console.error(error);
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else
      this.showErrorModal(JSON.parse(error.response).message);
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  navigateToKYC(): void {
    this.router.navigate(["/kyc"]);
  }
  setMaxDepositAmount(): void {
    this.amountToDeposit = Number(this.getIntegerPart(this.balance));
  }
  async getWETHaddress(): Promise<void> {
    this.WETHaddress = await this.web3Service.WETH();
  }

  async getAPY(): Promise<void> {
    const stakingAmountsOut = parseFloat(await this.web3Service.getAmountsOut(1, [this.item.stakingToken, this.WETHaddress]));
    const rewardAmountsOut = parseFloat(await this.web3Service.getAmountsOut(1, [this.item.poolToken, this.WETHaddress]));
    const rewardsPerSec = new BigNumber(await this.web3Service.rewardPerSec(this.item.poolAddress)).shiftedBy(-18).toNumber();
    const allStakedAmount = new BigNumber(await this.web3Service.allStakedAmount(this.item.poolAddress)).shiftedBy(-18).toNumber();

    this.allStakeAmount = new Intl.NumberFormat('en-US', {
      // @ts-ignore
      notation: 'compact',
      compactDisplay: 'short'
    }).format(allStakedAmount);

    const apy = (rewardAmountsOut * rewardsPerSec * 86400 * 365) / (stakingAmountsOut * allStakedAmount) * 100;
    this.apy = apy;
    const currentTime = Math.floor(Date.now() / 1000);
    if (Number.isNaN(apy) || !Number.isFinite(apy) || currentTime > this.item.finishTime) {
      this.apy = 0;
    }
    console.log('APY: ' + this.apy);
  }

  getIntegerPart(number: number): string {
    return Math.floor(number).toFixed(0);
  }

  getTranslatedShowPeriod(origValue: BigNumber): string {
    const value = Number(origValue);
    const timerViewDays = Math.floor(value / (3600 * 24));
    const timerViewHours = Math.floor(value % (3600 * 24) / 3600);
    const timerViewMin = Math.floor(value % 3600 / 60);
    const timerViewSec = Math.floor(value % 60);
    let stringData = "";
    if (timerViewDays)
      stringData += `${timerViewDays} ${this.translate.instant('time.day')} `;
    if (timerViewHours)
      stringData += `${timerViewHours} ${this.translate.instant('time.hours')} `;
    if (timerViewMin)
      stringData += `${timerViewMin} ${this.translate.instant('time.min')} `;
    if (timerViewSec)
      stringData += `${timerViewSec} ${this.translate.instant('time.ss')} `;
    return stringData;
  }

  public getPenalty(penaltyValue: BigNumber): any {
    const parsetValue = Number(penaltyValue);
    return (parsetValue/100);
  }

  async ngOnDestroy() {
  }
}
