<main class="dashboard-main">
  <section class="deals-section p-40">

    <div class="__deals-list">
      <mat-spinner *ngIf="waitingDeals" class="__loading" diameter="40"></mat-spinner>

      <div class="card not-found" *ngIf="!waitingDeals && deals.length == 0">
        <div class="icon icomoon-box"></div>
        <div class="not-found__text">{{'noDealsYet' | translate}}</div>
      </div>

      <div class="card-list per-row-3">
        <div class="deal-card card cursor-pointer" [routerLink]="['/equity-deal', item.guid]" *ngFor="let item of deals">
          <div class="deal-img" *ngIf="item.imgUrl">
            <img src="{{item.imgUrl}}" />
          </div>
          <div class="card-content">
            <div class="d-flex align-it align-items-center justify-content-between">
              <div class="token-name token-name-inline">
                <img *ngIf="item.logoUrl" [src]="item.logoUrl" />
                <div class="token-title">{{item.name}}</div>
              </div>
            </div>
          </div>

          <div class="card-footer">
            <div class="row" *ngIf="item.totalRaise; else summary">
              <div class="col-12">
                <div class="caption text-muted ">
                  {{'totalRaise' | translate}}
                </div>
                <div class="one-line total-raise" [innerHTML]="item.totalRaise"></div>
              </div>
            </div>
            <ng-template #summary>
              <div class="deal-card__info">
                <div class="row g-2">
                  <div class="col-6">
                    <div class="caption text-muted">{{(item.deadline && item.wireDeadline) ? ('signingDeadline' | translate) : ('deadline' | translate)}}</div>
                    <div>{{item.deadline | date : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
                  </div>
                  <div class="col-6" *ngIf="item.wireDeadline">
                    <div class="caption text-muted">{{'wiringDeadline' | translate}}</div>
                    <div>{{item.wireDeadline | date : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>

        </div>
      </div>
    </div>
  </section>
</main>
