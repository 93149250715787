import { Component, Input, OnInit } from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TranslateService} from "@ngx-translate/core";

@Component({
  templateUrl: './dlg-tier-info.component.html',
})

export class DlgTierInfoComponent implements OnInit {
  @Input() tierId: number;
  @Input() tierProgress: number;
  @Input() tierAmount: string;
  @Input() currentAmount: string;
  @Input() leftAmount: string;
  constructor(
    public dialogRef: MatDialogRef<DlgTierInfoComponent>,
    public translate: TranslateService
  ) {}
  async ngOnInit(): Promise<void> {
  }

  getTranslatedTier(tierIndex: number) {
    if (tierIndex == -1)
      return this.translate.instant('none');
    if (tierIndex == 0)
      return this.translate.instant('copperBull');
    if (tierIndex == 1)
      return this.translate.instant('bronzeBull');
    if (tierIndex == 2)
      return this.translate.instant('silverBull');
    if (tierIndex == 3)
      return this.translate.instant('goldenBull');
    if (tierIndex == 4)
      return this.translate.instant('titaniumBull');
    if (tierIndex == 5)
      return this.translate.instant('platinumBull');
    return this.translate.instant('none');
  }
}
