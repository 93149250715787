import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StakingPoolDTO, UsersServiceProxy } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';
import { Web3Service } from 'src/shared/web3-service';
import { BigNumber } from 'bignumber.js';
import {TranslateService} from "@ngx-translate/core";
import { UserSessionProvider } from '../../shared/user-session-provider';
@Component({
  templateUrl: './dlg-locker-pool.component.html',
})

export class DlgLockerPoolComponent extends ComponentBase implements OnInit {

  constructor(
    private web3Service: Web3Service,
    private _dialog: MatDialog,
    public translate: TranslateService,
    private readonly userService: UsersServiceProxy
  ) {
    console.log('DlgLockerPoolComponent constructor');
    super();
    console.log('after super');
   }

  @Input() item: StakingPoolDTO;
  @Input() currentStakeAmount: string;
  @Input() isDepositMode: boolean;
  @Input() account: string;
  @Input() depositAmount: number;

  balance: number;
  allowance: number;
  withdrawAmount: number;
  waiting: boolean;
  penaltyBP: number;
  staked: boolean;
  stakingTokenDecimals: number;

  isApprovedToken: boolean;

  public userLockingStartTimestamp: number = 0;

  async ngOnInit(): Promise<void> {
    console.log('DlgLockerPoolComponent ngOnInit')
    await this.web3Service.initWeb3();
    await this.updateAllowance();
    await this.getBalance();
    this.stakingTokenDecimals = parseInt(await this.web3Service.GetDecimals(this.item.stakingToken));
    this.userLockingStartTimestamp = parseInt(await this.web3Service.getUserLockingStart(this.account));

    if(parseInt(this.currentStakeAmount) > 0) {
      this.staked = true;
      await this.getPenalty();
    }
  }

  async getBalance(): Promise<void> {
    this.web3Service.GetTokenBalance(this.account, this.item.stakingToken).then((balance) => {
     this.balance = this.toNumberFromWei(balance, 18);
    });
  }

  async getPenalty(): Promise<void> {
    this.web3Service.getPoolPenaltyBP(this.item.startTime, this.item.poolAddress).then((penalty) => {
      console.log('penaltyBP', penalty)
      this.penaltyBP = penalty.toString();
     });
  }

  async updateAllowance(): Promise<void> {
    const resp = await this.web3Service.GetAllowance(this.account, this.item.poolToken, this.item.poolAddress);
    this.allowance = this.toNumberFromWei(resp, 18);
  }

  public get approved(): boolean {
    return !this.isDepositMode || this.isApprovedToken || this.allowance >= this.depositAmount;
  }

  public get currentPenalty(): number {
    return this.withdrawAmount * this.penaltyBP / 10000;
  }

  async depositClick(): Promise<void> {
    if(!this.approved)
      return;
    this.waiting = true;
    const userData = await this.userService.getMe().toPromise()
    const contractEventsSource = this.web3Service.depositToPool(this.account, this.depositAmount, this.stakingTokenDecimals, this.item.poolAddress, userData?.referrer ? userData.referrer : undefined);

    try {
      let response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this._dialog.closeAll();
      this.showInfoModal(this.translate.instant('confirmedTransaction'));
    } catch (err) {
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async withdrawClick(): Promise<void> {
    console.log("poolAddress: " + this.item.poolAddress);

    this.waiting = true;
    const contractEventsSource = this.web3Service.withdrawFromPool(this.account, this.withdrawAmount, this.stakingTokenDecimals, this.item.poolAddress);

    try {
      let response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this._dialog.closeAll();
      this.showInfoModal(this.translate.instant('confirmedTransaction'));
    } catch (err) {
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async approveClick(): Promise<void> {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.approve(
      this.account,
      this.item.stakingToken,
      this.item.poolAddress
    );

    try {
      await contractEventsSource.receipt$.toPromise();

      this.isApprovedToken = true;
      this.showInfoModal(this.translate.instant('confirmedTransaction'));
      // this.depositToPoolClick();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  setMaxDepositAmount(): void {
    this.depositAmount = Number(this.getIntegerPart(this.balance));
  }

  setMaxWithdrawAmount(): void {
    this.withdrawAmount = parseFloat(this.toNumberFromWei(this.currentStakeAmount, this.stakingTokenDecimals).toString());
  }

  getIntegerPart(number: number): string {
    return Math.floor(number).toFixed(0);
  }
}
