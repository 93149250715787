import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Web3Service } from '../../shared/web3-service';

export interface IDlgContractSubmittedData {
  tx: string;
}

@Component({
  selector: 'dlg-contract-submitted',
  templateUrl: './dlg-contract-submitted.component.html',
  styleUrls: ['./dlg-contract-submitted.component.scss']
})
export class DlgContractSubmittedComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DlgContractSubmittedComponent>,
    private web3Service: Web3Service,
    @Inject(MAT_DIALOG_DATA) public data: IDlgContractSubmittedData
  ) {
    this.chainIdNumber = this.web3Service.chainIdNumber;
  }

  chainIdNumber: number;
  ngOnInit(): void {}
}
