import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { environment } from '../../environments/environment';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {DealServiceProxy, UsersServiceProxy} from '../../service-proxies/service-proxies';
import { menu, MenuItem } from './menu';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html',
  styleUrls: ['./app-aside.component.scss'],
})
export class AppAsideComponent implements OnInit {

  public menuItems: MenuItem[];

  constructor(
    private router: Router,
    private dealService: DealServiceProxy,
    private userSessionProvider: UserSessionProvider,
    private usersService: UsersServiceProxy,
    private http: HttpClient
  ) {
  }

  public routerPath: string;
  public routerPathAddress: string;
  public vestingSeedAddress: string = environment.bsc.vestingSeedAddress;
  public vestingStrategicAddress: string = environment.bsc.vestingStrategicAddress;
  public vestingPrivateAddress: string = environment.bsc.vestingPrivateAddress;
  public vestingBountyAddress: string = environment.bsc.vestingBountyAddress;
  public tokenPrice: any;

  kycConfirmed: boolean = false;

  async ngOnInit() {
    this.getTokenPrice();
    const chainId = this.userSessionProvider.getChainId();
    this.menuItems = menu;//.filter(i=>  i.supportedChains.includes(chainId) || i.supportedChains.length == 0);
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.routerPath = this.router.url.toString();
        let urlParts = this.routerPath.split('?');
        if (urlParts.length >= 2) {
          this.routerPath = urlParts[0];
          let urlQuery = urlParts[1].split('=');
          this.routerPathAddress = urlQuery[1];
        }
        console.info("before isAuthorized");
        if (this.userSessionProvider.isAuthorized) {
          console.info("isAuthorized");
          this.usersService.getMe()
            .subscribe(
              result => {
                console.info("result.kycConfirmed");
                this.kycConfirmed = result.kycConfirmed;
              },
              error => {
                console.error("result.kycConfirmed");
                this.processServiceError(error);
              }
            );
        }
      }
    });

    if (this.userSessionProvider.linkedWallet) {
      const registeredEquityDeals = await this.dealService.getDealWhereUserRegistrated(this.userSessionProvider.linkedWallet).toPromise();
      if (registeredEquityDeals.some(s => s.dealType === 4)) {
        this.menuItems.filter(s => s.id === 4)[0].childItems.filter(s => s.id === 9)[0].hidden = true;
      }
    }

    const elementsArray = document.querySelectorAll('.sidenav__nav a.nav-link');

    elementsArray.forEach(function(elem) {
      elem.addEventListener('click', function() {
        document.body.classList.remove('mob-menu-active');
      });
    });
  }

  processServiceError(error: any) {
      console.error(JSON.parse(error.response).message);
  }
  toggleSubMenu(event: any){
    const parentItem = event.target.parentNode;

    const siblingItems = Array.from(parentItem.parentNode.children).filter((child: any) => child !== parentItem);

    siblingItems.forEach((siblingItem: any) => {
      const submenu = siblingItem.querySelector('.sub-menu');
      if (submenu) {
        submenu.classList.remove('show');
      }
    });

    const submenu = parentItem.querySelector('.sub-menu');
    if (submenu) {
      submenu.classList.toggle('show');
    }
  }

  // not used yet
  public isMenuAvailable(menuGroup : string): boolean {
    const chainId = this.userSessionProvider.getChainId();
    return !menu.filter(menuItem => menuItem.supportedChains.includes(chainId))
  }

  public getTokenPrice(): void {
    this.http.get('https://api.coingecko.com/api/v3/simple/price?ids=bullperks&vs_currencies=usd').toPromise().then((res) => {
      this.tokenPrice = res
    }).catch((error) => {
      console.error(error);
      setTimeout(() => {
        this.getTokenPrice();
      }, 10000)
    });
  }
}
