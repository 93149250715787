import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { AlertService } from '../shared-dlg.module';
import {
  AccountServiceProxy,
  ChangeWalletServiceProxy
} from '../../service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { MatDialog } from '@angular/material/dialog';
import { UserSessionProvider } from '../../shared/user-session-provider';
import BigNumber from "bignumber.js";
import swal from "sweetalert2";

@Component({
  templateUrl: './change-wallet-confirmation.component.html',
  styleUrls: ['./change-wallet-confirmation.component.scss'],
})
export class ChangeWalletConfirmationPageComponent extends ComponentBase implements OnInit {
  constructor(
    private userSessionProvider: UserSessionProvider,
    private _alertSrv: AlertService,
    private accountService: AccountServiceProxy,
    private route: ActivatedRoute,
    private router: Router,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private changeWalletService: ChangeWalletServiceProxy,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  waiting: boolean = false;
  account: string = '';
  signUpEmail: string = '';
  changeWalletGuid: string = '';
  changeWalletInfo: any


  ngOnInit() {
    this.changeWalletGuid = this.activatedRoute.snapshot.params.guid;

    this.route.params.subscribe((routeParams:any) => {
      this.changeWalletGuid = routeParams.guid;
      this.changeWalletService.getOneWallet(this.changeWalletGuid).subscribe(
        res => {
          this.changeWalletInfo = res
        },
        error => {
          console.log(error.error);
        },
      )
    });
  }

  get isOneDayPassed(): boolean {
    const createdDate = new Date(this.changeWalletInfo.createDate);

    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - createdDate.getTime();

    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    return timeDifference >= oneDayInMilliseconds;
  }

  async confirmWalletChange() {
    let signature = await this.web3Service.PersonalSign(this.changeWalletInfo.id, this.userSessionProvider.linkedWallet);
    this.waiting = true;

    this.changeWalletService.confirmWalletChange(new BigNumber(this.changeWalletInfo.id).toNumber(), this.userSessionProvider.linkedWallet, signature).subscribe(
      res => {
        this.waiting = false;
        swal.fire({
          title: 'Request Confirmed',
          text: 'Please connect with the old wallet and pass KYC again to start the tier transfer process.',
          icon: "success",
          confirmButtonText: `<div class="d-flex align-items-center g-1">Log out</div>`,
        }).then(async (res) => {
          if (res.isConfirmed) await this.signOut();
        });
      },
      error => {
        this.waiting = false;
        console.log(error);
        console.log('isOneDayPassed', this.isOneDayPassed)
        if (this.isOneDayPassed) {
          swal.fire({
            title: 'Change Wallet',
            text: '24 hours passed since you request wallet change. You need to create new request for wallet change',
            icon: "error",
            confirmButtonText: `<div class="d-flex align-items-center g-1"><i class="icon icomoon-checkmark me-1"></i> Got it</div>`
          });
        }
      },
    );
  }

  async signOut() {
    console.log('signOut');
    this.userSessionProvider.finishSession();
    await this.web3Service.WalletDisconnect();
    location.reload();
    return;
  }
}
