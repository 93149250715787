<div class="user-polls-page">
  <div class="user-polls-wrapper">
    <div class="user-poll" *ngFor="let poll of pollsWithUserVotedProperty">
      <div class="user-poll-title">
        <img src="assets/images/polls/info-icon.svg" alt="info-icon">
        <p class="user-poll-title-text">{{poll.title}}</p>
      </div>

      <div class="user-poll-description" [innerHTML]="poll.description | safeHtml"></div>

      <div class="user-polls-votes">
        <ng-container *ngIf="poll.userVoted || poll.isFinished">
          <div class="user-polls-vote" *ngFor="let vote of poll.options">
            <div class="user-polls-vote-info">
              <span class="user-polls-vote-info-answer">{{vote.option}}</span>
              <span class="user-polls-vote-info-percentage">{{vote.percentage}}%</span>
            </div>
            <div class="user-polls-vote-progress-wrapper">
              <i class="icon icomoon-checked" *ngIf="optionUserVotedFor(vote.id)"></i>
              <div class="user-polls-vote-progress">
                <div class="user-polls-vote-progress-bar" role="progressbar" [style.width.%]="vote.percentage"></div>
              </div>
            </div>
          </div>
        </ng-container>


        <div class="user-poll-info-wrapper">
          <p class="user-poll-date">{{poll.createdDate + 'Z' | date :'dd MMM, H:mm': undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</p>
          <p class="user-polls-votes-total">{{poll.votes.length}} {{poll.votes.length > 1 ? ('votes' | translate) : ('vote' | translate)}}</p>
        </div>

        <ng-container *ngIf="!poll.userVoted && canVote && !poll.isFinished">
          <div class="user-polls-option" *ngFor="let option of poll.options">
            <a mat-raised-button class="btn-outline-grey btn-icon btn-icon-left" (click)="makeVote(option)">
              {{option.option}}
            </a>
          </div>
        </ng-container>

      </div>

      <div *ngIf="!poll.userVoted && !poll.isFinished" class="user-poll-warning">
        <img src="assets/images/polls/warning-icon.svg" alt="warning-icon">
        <p class="user-poll-warning-text">{{ canVote ? ('youWillNotAbleChangeSelection' | translate) : ('connectWalletWhichHasTierToParticipateVoting' | translate)}} </p>
      </div>

    </div>
  </div>

</div>
