import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { UserSessionProvider } from '../../shared/user-session-provider';
import { Web3Service } from '../../shared/web3-service';
import { AlertService } from '../shared-dlg.module';
import { ComponentBase } from '../../shared/component-base';
import { DlgContractService } from '../dlg-contract.service';
import { EventBus } from '../../shared/event-bus';
import { KYC_required } from 'src/internal/kyc.decorator';

import { environment } from '../../environments/environment';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'dlg-transfer-modal',
  templateUrl: './dlg-transfer-modal.component.html',
  styleUrls: ['./dlg-transfer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DlgTransferModalComponent extends ComponentBase implements OnInit {
  account: string | null;
  lockedBalance: string;

  waiting: boolean = false;
  selectedAmount: number = 0;

  constructor(
    public web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private _alertSrv: AlertService,
    private _dialog: MatDialog,
    private eventBus: EventBus,
    private _dlgContractSrv: DlgContractService,
    public translate: TranslateService
  ) {
    super();
  }

  async ngOnInit() {
    this.account = this.userSessionProvider.linkedWallet;
    if (this.account) {
      this.updateUserData();
    }
  }

  async updateUserData() {
    this.lockedBalance = await this.web3Service.getLockedTokenAmount(this.web3Service.lockerAddress, this.account);
  }

  async setMaxBalance() {
    this.selectedAmount = this.toNumberFromWeiFixed(this.lockedBalance, 18, 8);
  }

  @KYC_required
  async transferClick() {
    this.waiting = true;

    const contractEventsSource = this.web3Service.lockerTransfer(
      this.account,
      environment.bsc.stackingAddress,
      this.selectedAmount,
      18
    );

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        this._alertSrv.show(this.translate.instant('transactionSubmitted'));
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      this._alertSrv.show(this.translate.instant('confirmedTransaction'));
      console.log('emit needUpdateUsersInfo');
      this.eventBus.needUpdateUsersInfo.emit();
    } catch (err) {
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }
}
