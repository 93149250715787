import { ExtraModuleInjector } from "./services/decorator.service";
import swal from "sweetalert2"
import {TranslateService} from "@ngx-translate/core";
import { UsersServiceProxy } from "src/service-proxies/service-proxies";

export function KYC_required(target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  descriptor.value = async function (...args: any) {
    const service = ExtraModuleInjector.get<UsersServiceProxy>(UsersServiceProxy);
    const translateService = ExtraModuleInjector.get<TranslateService>(TranslateService);
    const kycResult = await service.getMe().toPromise();
    if (kycResult.kycConfirmed) {
      return originalMethod.apply(this, args);
    } else {
      swal.fire({
        text: translateService.instant('youMustCompleteKycFormFirst'),
        icon: 'warning'
      });
    }
  };
  return descriptor;
}

