<info-join-deals></info-join-deals>

<main class="dashboard-main" [ngClass]="{'loading-page': waiting}">

  <section class="dashboard-section __deal-main-info pt-40 flexible" *ngIf="!waiting">
    <div class="card mb-20">
      <div class="card-header d-flex flex-wrap align-items-center">
        <div class="token-name token-name-inline">
          <img *ngIf="deal.logoUrl" [src]="deal.logoUrl" />
          <div class="token-title">{{deal?.name}}</div>
        </div>

        <div class="d-lg-flex align-items-center ms-lg-auto __deal-header-link">

          <div (click)="userTierIndex >= 3 ? true : showWarningModal()" [matTooltip]="userTierIndex < 3 ? ('youMustBeGoldenBullOrAbove' | translate) : ''">
            <a [href]="deal.investmentURL" mat-raised-button color="primary" class="__join-link ms-lg-2 btn-icon btn-icon-left"
               [disabled]="userTierIndex < 3"
               style="{{userTierIndex < 3 ? 'pointer-events: none' : 'pointer-events: auto'}}">
              <span class="invest-label">{{'invest' | translate}}</span>
              <mat-icon>launch</mat-icon>
            </a>
          </div>

        </div>
      </div>

      <div class="card-content deadlines" *ngIf="deal.deadline">
        <div class="row">
          <div class="col-md-6 col-lg-6 mb-4 mb-lg-0 order-1">
            <div class="d-flex align-items-center">
              <!--<button *ngIf="!isFinished" (click)="addToGoogleCalendar()" mat-icon-button
                      class="__add-to-google me-3" matTooltip="Add to Google Calendar" matTooltipPosition="above">
                <img src="../../assets/images/google-calendar.svg" alt="">
              </button>-->
              <div>
                <div *ngIf="!isFinished">
                  <span class="subtitle-1 mb-1">{{(deal.deadline && deal.wireDeadline) ? ('signingDeadline' | translate) : ('deadline' | translate)}}</span>

                  <div class="countdown h6 mb-1">
                    <span *ngIf="timerViewDays">{{timerViewDays}}{{'time.day' | translate}}</span>
                    <span *ngIf="timerViewHours"> {{timerViewHours}}{{'time.hour' | translate}}</span>
                    <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}}{{'time.minute' | translate}}</span>
                    <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}}{{'time.second' | translate}}</span>
                  </div>
                </div>

                <div class="subtitle-1 mb-1" *ngIf="isFinished">{{(deal.deadline && deal.wireDeadline) ? ('signingDeadline' | translate) : ('deadline' | translate)}}</div>
                <div class="h6 mb-1" *ngIf="isFinished">{{'finished' | translate}}</div>

                <div class="small-text text-muted">{{deal.deadline | date:'dd MMM, yyyy' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="deal.wireDeadline">
          <div class="col-md-6 col-lg-6 mb-4 mb-lg-0 order-1">
            <div class="d-flex align-items-center">
              <!--<button *ngIf="!isFinished" (click)="addToGoogleCalendar()" mat-icon-button
                      class="__add-to-google me-3" matTooltip="Add to Google Calendar" matTooltipPosition="above">
                <img src="../../assets/images/google-calendar.svg" alt="">
              </button>-->
              <div>
                <div *ngIf="!isFinishedWire">
                  <span class="subtitle-1 mb-1">{{'wiringDeadline' | translate}}</span>

                  <div class="countdown h6 mb-1">
                    <span *ngIf="timerViewDaysWire">{{timerViewDaysWire}}{{'time.day' | translate}}</span>
                    <span *ngIf="timerViewHoursWire"> {{timerViewHoursWire}}{{'time.hour' | translate}}</span>
                    <span *ngIf="timerViewMinWire && !timerViewDaysWire"> {{timerViewMinWire}}{{'time.minute' | translate}}</span>
                    <span *ngIf="timerViewSecWire && !timerViewDaysWire"> {{timerViewSecWire}}{{'time.second' | translate}}</span>
                  </div>
                </div>

                <div class="subtitle-1 mb-1" *ngIf="isFinishedWire">{{'wiringDeadline' | translate}}</div>
                <div class="h6 mb-1" *ngIf="isFinishedWire">{{'finished' | translate}}</div>

                <div class="small-text text-muted">{{deal.wireDeadline | date:'dd MMM, yyyy' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-section __deal-description-info mb-40" *ngIf="!waiting">

    <div class="row">
      <div class="col-md-6 col-lg-6 mb-4 mb-lg-0">
        <div class="card mb-20">
          <div class="card-header">{{'about' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div id="summary" [innerHTML]="deal?.summary | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div *ngIf="deal?.problemAndSolution" class="card mb-20">
          <div class="card-header">{{'problemAndSolution' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.problemAndSolution | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div *ngIf="deal?.howWeMakeMoney" class="card mb-20">
          <div class="card-header">{{'howWeMakeMoney' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.howWeMakeMoney | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div *ngIf="deal?.team"  class="card mb-20">
          <div class="card-header">{{'team' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.team | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div *ngIf="deal?.advisors" class="card mb-20">
          <div class="card-header">{{'advisors' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.advisors | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-6 mb-4 mb-lg-0">

        <div *ngIf="deal?.investors"  class="card mb-20">
          <div class="card-header">{{'investors' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.investors | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div *ngIf="deal?.reasonsToInvest"  class="card mb-20">
          <div class="card-header">{{'reasonsToInvest' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.reasonsToInvest | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div *ngIf="deal?.rewards"  class="card mb-20">
          <div class="card-header">{{'rewards' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.rewards | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div class="card mb-20">
          <div class="card-header">{{'terms' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.terms | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div *ngIf="deal?.metrics" class="card mb-20">
          <div class="card-header">{{'metrics' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.metrics | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>


        <div *ngIf="deal?.market" class="card mb-20">
          <div class="card-header">{{'market' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.market | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div *ngIf="deal?.media" class="card mb-20">
          <div class="card-header">{{'media' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.media | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div class="card mb-20">
          <div class="card-header">{{'disclaimers' | translate}}</div>
          <div class="card-content">
            <div class="row">
              <div [innerHTML]="deal?.disclaimers | safeHtml" class="col-lg-12 card-content-data"></div>
            </div>
          </div>
        </div>

        <div #faqVideoElement *ngIf="faqItems?.length > 0" class="card mb-20" >
          <div class="card-header">{{'faq' | translate}}</div>
          <div class="card-content">
            <cdk-accordion class="accordion">
              <cdk-accordion-item
                *ngFor="let item of faqItems; index as i"
                #accordionItem="cdkAccordionItem"
                class="accordion-item"
                role="button"
                tabindex="0"
                [attr.id]="'accordion-header-' + i"
                [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + i">
                <div class="accordion-item-header" (click)="accordionItem.toggle()">
                  <div [innerHtml]="item.title.innerHTML | safeHtml"></div>
                  <span class="accordion-item-description">
                    <i class="icon" [ngClass]="accordionItem.expanded ? 'icomoon-chevron-up' : 'icomoon-chevron-down'"></i>
                  </span>
                </div>
                <div
                  class="accordion-item-body"
                  role="region"
                  [style.display]="accordionItem.expanded ? '' : 'none'"
                  [attr.id]="'accordion-body-' + i"
                  [attr.aria-labelledby]="'accordion-header-' + i">
                  <div *ngIf="item.video" [innerHtml]="item.video.outerHTML | safeHtml" class="faq-video"></div>
                  <div [innerHtml]="item.text.innerHTML | safeHtml"></div>
                </div>
              </cdk-accordion-item>
            </cdk-accordion>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
