import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';

type NewType = OnDestroy;

@Component({
  templateUrl: './dlg-disclaimer.component.html',
  styleUrls: ['./dlg-disclaimer.component.scss'],
})
export class DlgDisclaimerComponent implements OnInit, AfterViewInit, NewType {
  @ViewChild('disclaimerTextBlock', { static: false })
  disclaimerTextBlock: ElementRef;
  private endDocument$ = new Subject<boolean>();
  private listener: Subscription = new Subscription();

  public isButtonEnabled = this.endDocument$.asObservable();

  constructor(
    public dialogRef: MatDialogRef<DlgDisclaimerComponent>,
    private sanitizer: DomSanitizer
  ) {}

  ngOnDestroy(): void {
    if (this.listener) {
      this.listener.unsubscribe();
    }
  }

  @Input() disclaimer: string = '';
  @Input() disclaimerText: string = '';
  public disclaimerUrl: SafeUrl;

  ngOnInit() {
    this.disclaimerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.disclaimer + '?embedded=true'
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.disclaimerTextBlock.nativeElement;
      element.scrollTo(0, 0);
      this.listener = element.addEventListener('scroll', (event: any) => {
        if (
          (event.srcElement.offsetHeight + event.srcElement.scrollTop >=event.srcElement.scrollHeight) || 
          (event.srcElement.scrollHeight - event.srcElement.scrollTop <= event.srcElement.clientHeight * 1.2)
        ) {
          this.endDocument$.next(true);
        }
      });
    }, 1000);
  }
}
