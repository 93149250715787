import { Component, Input, OnInit } from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  templateUrl: './dlg-locker-info.component.html',
})

export class DlgLockerInfoComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DlgLockerInfoComponent>,
  ) {}

  async ngOnInit(): Promise<void> {
  }
}
