<div class="modal-header">
  <i class="icon icomoon-wallet"></i>
  <h5 class="modal-title">Change Wallet</h5>
  <button class="btn-close" [mat-dialog-close]="true"></button>
</div>

<div class="modal-body stack">

  <div class="warning-text">
    <div class="warning-title">
      <i class="icon icomoon-warning"></i>
      Warning
    </div>
    <ul>
      <li>Changing your wallet address will cause all your deals to disappear from your portfolio.</li>
      <li>For all previous deals you’ve joined, you must request wallet address changes on <a href="https://Bullclaimer.com" target="_blank">BullClaimer</a>.</li>
      <li>You will receive an email to confirm your request. You will also need to clear the KYC Selfie stage to confirm your identity.</li>
      <li>After clearing KYC, your tier will be transferred to the new wallet within 3 business days.</li>
      <li>Please put the new wallet address below and click Confirm when you are ready to change it.</li>
    </ul>
  </div>

  <mat-form-field appearance="fill" class="icon-form-field" [ngClass]="{'mat-form-field-error': isError}" hideRequiredMarker>
    <mat-label>New Wallet Address</mat-label>
    <input matInput type="text" [(ngModel)]="newWalletAddress" required>
  </mat-form-field>
  <mat-error *ngIf="isError">{{errorMessage}}</mat-error>

  <div class="row pt-4">
    <div class="col-md-6">
      <button mat-stroked-button class="w-100" [mat-dialog-close]="true">
        <i class="icon icomoon-close"></i>
        Cancel
      </button>
    </div>
    <div class="col-md-6">
      <button mat-raised-button color="primary" class="w-100" (click)="initiateWalletChange()">
        <i class="icon icomoon-checkmark"></i>
        Confirm
      </button>
    </div>
  </div>

</div>
