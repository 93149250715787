<div class="modal-header">
  <h5 class="modal-title">{{'lockingStartDate' | translate}}</h5>
  <button class="btn-close" [mat-dialog-close]></button>
</div>

<div class="modal-body ">
  <p class="mb-4 small-text">
    {{'participationWarningText' | translate}}
  </p>
  <button mat-raised-button color="primary" class="w-100" [mat-dialog-close]="true">
    {{'gotIt' | translate}}
  </button>
</div>
