import { Component, OnInit } from '@angular/core';
import {UserSessionProvider} from "../shared/user-session-provider";
import {TranslateService} from "@ngx-translate/core";
import { AccountServiceProxy } from 'src/service-proxies/service-proxies';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private userSessionProvider: UserSessionProvider, private translate: TranslateService, private accountServiceProxy: AccountServiceProxy) {
    const userLanguage = this.userSessionProvider.getLang;
    this.translate.setDefaultLang(userLanguage);
    this.translate.use(userLanguage);
  }
  title = 'blp-ui';

  ngOnInit(): void {
    this.accountServiceProxy.logUserVisit();
  }
}
