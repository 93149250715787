import '@swing.xyz/ui/web-components';

import { Component} from '@angular/core';

@Component({
    templateUrl: './swing-widget.component.html',
    styleUrls: ['./swing-widget.component.scss'],
})
export class SwingWidgetComponent {
    

}   