import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  DealServiceProxy,
  UsersServiceProxy,
} from 'src/service-proxies/service-proxies';
import { UserSessionProvider } from 'src/shared/user-session-provider';

interface IObjectKeys {
  [key: string]: string;
}

export const documentDeals = ['0x7fcc1d10a62d23fb1e1fd28817ee6a127480ede1'.toLowerCase(), '0x7202f8daa271a05b1737aff9eca034cf1e251ae1'.toLowerCase(), '0xa43a2189ead2f21b84c40ad9698011bcb667c240'.toLowerCase(), '0xd03ac5905da8b046526e01f28bbfdcf511ab3d10'.toLowerCase(), '0x7568bfddc2bf6afe4a72f7b30fbb65df839206cf'.toLowerCase(), '0xc74e8153a2334d3066bdda7449261f739ca61e32'.toLowerCase(), '0x32F578b51F107E208fAfC0555e94A63f62667572'.toLowerCase()];
const alwaysWhitelistUsers = ['0xaceaEcB820Be3f147FF40d1C221B25F10ee8dd92'.toLowerCase()];

@Injectable({ providedIn: 'root' })
export class DetailDealResolver
   {
  constructor(
    private userServiceProxy: UsersServiceProxy,
    private readonly router: Router,
    private userSessionProvider: UserSessionProvider,
    private dealServiceProxy: DealServiceProxy
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const dealAddress = route.queryParams.address;

    // Checking if deal with documents (// replace with API requiest)
    if (documentDeals.includes(dealAddress)) { // document deal behaviour

      // 1 checking whitelist
      // 2 checking KYC
      // 3 checking documents

      // const whiteListCheck = await this.dealServiceProxy
      //   .checkWhiteList(dealAddress)
      //   .toPromise();
      // console.log('whiteListCheck', whiteListCheck, !alwaysWhitelistUsers.includes(this.userSessionProvider.linkedWallet));
      // if (!whiteListCheck.value && !alwaysWhitelistUsers.includes(this.userSessionProvider.linkedWallet.toLowerCase())) {
      //   console.warn('resolve 1', );
      //   return this.router.navigateByUrl('');
      // }

      const kycCheck = await this.userServiceProxy.getMe().toPromise();
      if(!kycCheck.kycConfirmed && !alwaysWhitelistUsers.includes(this.userSessionProvider.linkedWallet)){
        console.warn('resolve 2', this.userSessionProvider.linkedWallet, !kycCheck.kycConfirmed, !alwaysWhitelistUsers.includes(this.userSessionProvider.linkedWallet));
        return this.router.navigateByUrl('');
      }

      return of();
    } else { // normal deal behaviour
      return of();
    }
  }
}
