import { NgModule, APP_INITIALIZER, Injector, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppMobileHeaderComponent } from './app-mobile-header/app-mobile-header.component';
import { SharedDlgModule } from './shared-dlg.module';
import { AppComponent } from './app.component';
import { AppDashboardHeaderComponent } from './app-dashboard-header/app-dashboard-header.component';
import { DealDetailComponent } from './deal-detail/deal-detail.component';
import { DealsComponent } from './deals/deals.component';
import { EquityDealsComponent } from './equity-deals/equity-deals.component';
import { EquityDealComponent } from './equity-deal/equity-deal.component';
import { MainPageComponent } from './main/main.component';
import { AppAsideComponent } from './app-aside/app-aside.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppWalletComponent } from './app-wallet/app-wallet.component';
import { DlgSwitchNetworkComponent } from './dlg-switch-network';
import { DlgWalletComponent } from './dlg-wallet';
import { DlgUnlockWalletComponent } from './dlg-unlock-wallet';
import { DlgContractProcessingComponent } from './dlg-contract-processing';
import { DlgContractSubmittedComponent } from './dlg-contract-submitted';
import { DlgLockerModalComponent } from './dlg-locker-modal';
import { DlgTransferModalComponent } from './dlg-transfer-modal';
import { DlgContractService } from './dlg-contract.service';
import { DlgLockerPoolComponent } from './dlg-locker-pool';

import { BrowserStorageProvider } from '../shared/browser-storage-provider';
import { UserSessionProvider } from '../shared/user-session-provider';
import { EventBus } from '../shared/event-bus';
import { Web3Service } from '../shared/web3-service';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ServiceProxyModule } from '../service-proxies/service-proxy.module';
import {API_BASE_URL, AUTH_BASE_URL, ChangeWalletServiceProxy, PollsServiceProxy} from '../service-proxies/service-proxies';
import { environment } from '../environments/environment';
import { ConfirmEmailPageComponent } from './confirm-email/confirm-email.component';
import { SignUpPageComponent } from './sign-up/sign-up.component';
import { KYCPageComponent } from './KYC/kyc.component';
import { LoginPageComponent } from './login/login.component';
import { DealViewerComponent } from './deal-viewer/deal-viewer.component';
import { InfoJoinDealsComponent } from './info-join-deals/info-join-deals.component';
import { BLPDealDetailComponent } from './blp-deal-detail/blp-deal-detail.component';
import { PortfolioComponent } from './portfolio/portfolio.component';

import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
//import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { VestingComponent } from './vesting/vesting.component';
import { StakingComponent } from './staking/staking.component';
import { FarmingComponent } from './farming/farming.component';
import { PoolDetailComponent } from './pool-detail/pool-detail.component';
import {CreatePoolComponent} from "./create-pool/create-pool.component";
import { EditPoolComponent } from "./edit-pool/edit-pool.component";
import { PoolViewerComponent } from './pool-viewer/pool-viewer.component';
import { ClaimingComponent } from './claiming/claiming.component';
import { ClaimingDealDetailComponent } from './claiming-deal-detail/claiming-deal-detail.component';
import { EditClaimingDealComponent } from './edit-claiming-deal/edit-claiming-deal.component';
import { CreateClaimingDealComponent } from './create-claiming-deal/create-claiming-deal.component';
import { DlgLockerInfoComponent } from './dlg-locker-info';
import { DlgTierInfoComponent } from './dlg-tier-info';
import { NgChartsModule } from 'ng2-charts';
import { AddressBookComponent } from './address-book/address-book.component';
import { DlgAddressBookComponent } from './dlg-address-book';
import { ExtraModuleInjector } from 'src/internal/services/decorator.service';
import { DlgDisclaimerComponent } from './dlg-disclaimer/dlg-disclaimer.component';
import { SwingWidgetComponent } from './swing-widget-component/swing-widget.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { DetailDealResolver } from './deal-detail/detail-deal.resolver';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import {DatePipe, DecimalPipe, registerLocaleData} from "@angular/common";

import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeTr from "@angular/common/locales/tr";
import localeRu from "@angular/common/locales/ru";
import localeHi from "@angular/common/locales/hi";
import localeZh from "@angular/common/locales/zh";
import localeJa from "@angular/common/locales/ja";
import {UserPollsComponent} from "./user-polls/user-polls.component";
import { ReferralPageComponent } from './referral-page/referral.component';
import { UltradeWidgetComponent } from './ultrade-widget-component/ultrade-widget-component';
import { UltradeComponent } from '@ultrade/angular-embed';
import {MatPaginatorModule} from "@angular/material/paginator";
import {DlgDepositModalComponent} from "./dlg-deposit-modal";
import { OtcDealsComponent } from './otc-deals/otc-deals.component';
import { DlgWalletTransferComponent } from './dlg-wallet-transfer';
import { ChangeWalletConfirmationPageComponent } from './change-wallet-confirmation/change-wallet-confirmation.component';
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeTr);
registerLocaleData(localeRu);
registerLocaleData(localeHi);
registerLocaleData(localeZh);
registerLocaleData(localeJa);


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    BrowserModule,
    AppRoutingModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    PipesModule,
    HttpClientModule,
    ServiceProxyModule,
    SharedDlgModule,
    RecaptchaModule,
    //RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    NgChartsModule,
    HttpClientModule,
    CdkAccordionModule,
    UltradeComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    MatPaginatorModule,
  ],
  declarations: [
    AppComponent,
    AppMobileHeaderComponent,
    AppAsideComponent,
    AppDashboardHeaderComponent,
    DealDetailComponent,
    BLPDealDetailComponent,
    MainPageComponent,
    DealsComponent,
    EquityDealsComponent,
    EquityDealComponent,
    PortfolioComponent,
    ConfirmEmailPageComponent,
    SignUpPageComponent,
    KYCPageComponent,
    LoginPageComponent,
    AppFooterComponent,
    AppWalletComponent,
    DlgSwitchNetworkComponent,
    DlgWalletComponent,
    DlgUnlockWalletComponent,
    DlgContractProcessingComponent,
    DlgContractSubmittedComponent,
    DlgLockerModalComponent,
    DlgTransferModalComponent,
    DlgLockerPoolComponent,
    DealViewerComponent,
    InfoJoinDealsComponent,
    VestingComponent,
    StakingComponent,
    FarmingComponent,
    PoolDetailComponent,
    CreatePoolComponent,
    EditPoolComponent,
    PoolViewerComponent,
    ClaimingComponent,
    ClaimingDealDetailComponent,
    EditClaimingDealComponent,
    CreateClaimingDealComponent,
    DlgLockerInfoComponent,
    DlgTierInfoComponent,
    AddressBookComponent,
    DlgAddressBookComponent,
    DlgWalletTransferComponent,
    DlgDisclaimerComponent,
    SwingWidgetComponent,
    UserPollsComponent,
    ReferralPageComponent,
    UltradeWidgetComponent,
    DlgDepositModalComponent,
    OtcDealsComponent,
    ChangeWalletConfirmationPageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    BrowserStorageProvider,
    UserSessionProvider,
    EventBus,
    Web3Service,
    PollsServiceProxy,
    ChangeWalletServiceProxy,
    DatePipe,
    DecimalPipe,
    { provide: API_BASE_URL, useValue: environment.remoteServiceBaseUrl }, // выставляем url web api для проксей
    { provide: AUTH_BASE_URL, useValue: environment.authServiceBaseUrl }, // URL for auth service (BullAuth)
    {
      provide: APP_INITIALIZER,
      useFactory: InitFactory,
      deps: [BrowserStorageProvider, UserSessionProvider, Web3Service],
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.captchaSiteKey } as RecaptchaSettings,
    },
    DlgContractService,
    ExtraModuleInjector,
    DetailDealResolver
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // do not remove extraModuleInjector
  constructor(private readonly extraModuleInjector: ExtraModuleInjector) {}

  // to avoid extraModuleInjector be treeshacked
  public getInject():any{
    return this.extraModuleInjector;
  }
}

function InitFactory(
  browserStorage: BrowserStorageProvider,
  userSession: UserSessionProvider,
  web3Service: Web3Service
) {
  return async function (): Promise<void> {
    // Make right init things here
  };
}
