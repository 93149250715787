<section class="dashboard-section p-40">
  <div class="sign-up-form card" *ngIf="step==1">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">{{'unlockWallet' | translate}}</div>
      <i class="icon icomoon-unlock __icon"></i>
      <button type="button"
              mat-raised-button
              color="primary"
              class="w-100"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="waiting"
              (click)="unlockWallet()">
        {{'unlockWallet' | translate}}
      </button>
    </div>
  </div>


  <!--<div class="white-card sign-up-form mb-20" *ngIf="step==1">
    <div class="white-card__header">
      <div class="label">Step 2</div>
    </div>
    <div class="white-card__content">
      <form>
        <button type="button" class="btn btn-primary w-100" (click)="unlockWallet()"
                [ngClass]="{'wait': waiting}"
                [disabled]="waiting">
          Sign request
        </button>
      </form>
    </div>
  </div>-->

  <div class="sign-up-form card" *ngIf="step==2">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">{{'registration' | translate}}</div>
      <i class="icon icomoon-unlock __icon"></i>
      <button type="button"
              id="bp-finish-registration"
              mat-raised-button
              color="primary"
              class="w-100"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="waiting"
              (click)="confirmSignUp()"
      >
        {{'signAndFinishRegistration' | translate}}
      </button>
    </div>
  </div>

  <div class="sign-up-form card" *ngIf="step==4">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">{{'registrationFinished' | translate}}</div>
      <i class="icon icomoon-checked __icon"></i>
      <div class="small-text mute-80">
        {{'youHaveSuccessfullyRegistered' | translate}}
      </div>

      <a href="/" mat-raised-button  color="primary" class="w-100">{{'dashboard' | translate}}</a>
    </div>
  </div>
</section>

