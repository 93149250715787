import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  AddressBookEntryDTO,
  AddressBookRequestLiteDTO,
  AddressBookServiceProxy,
  NetworkType,
} from '../../service-proxies/service-proxies';
import { ComponentBase } from '../../shared/component-base';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Router } from '@angular/router';
import { Web3Service } from '../../shared/web3-service';
import { SolanaWeb3Service } from 'src/internal/services/solana.service';
import { catchError, filter, switchMap, switchMapTo } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Component({
  templateUrl: './dlg-address-book.component.html',
})
export class DlgAddressBookComponent extends ComponentBase implements OnInit {
  private readonly evmNetworks: Array<number> = [
    NetworkType.BSC,
    NetworkType.BSCTest,
    NetworkType.Ethereum,
    NetworkType.Rinkeby,
    NetworkType.Gorli,
    NetworkType.Kovan,
    NetworkType.Polygon,
    NetworkType.PolygonTest,
    NetworkType.Heco,
    NetworkType.HecoTest,
    NetworkType.Avalanche,
    NetworkType.Fantom,
    NetworkType.KCC,
    NetworkType.KCCTest,
    NetworkType.Wanchain,
    NetworkType.Skale,
    NetworkType.Harmony,
    NetworkType.OKC,
    NetworkType.Arbitrum,
    NetworkType.Optimism,
    NetworkType.Base,
    NetworkType.Moonbeam,
    NetworkType.Lightlink,
    NetworkType.Blast
  ];

  constructor(
    public dialogRef: MatDialogRef<DlgAddressBookComponent>,
    private userSessionProvider: UserSessionProvider,
    private web3Service: Web3Service,
    private router: Router,
    private addressBookServiceProxy: AddressBookServiceProxy,
    private readonly solanaService: SolanaWeb3Service,
    public translate: TranslateService
  ) {
    super();
  }
  @Input() actionAddressMode: string;
  @Input() dto: AddressBookEntryDTO;
  waiting: boolean;
  emissionAddress: string;
  async ngOnInit(): Promise<void> {
    console.log('linkedWallet');
    if (this.userSessionProvider.authToken) {
      //this.eventLogin(this.userSessionProvider.linkedWallet);
      this.emissionAddress = this.dto.emissionAddress;
    } else {
      console.error('Not Auth. redirect to login page');
      this.router.navigate(['/login']);
    }
  }

  async saveAddressClick(): Promise<void> {
    if (this.actionAddressMode === 'edit') {
      const result = await this.showConfirmationModal(
        this.translate.instant('changingWalletInAddressBookWillChangeYourEmissionAddress'),
        this.translate.instant('changeAddress')
      );
      if (!result.isConfirmed) {
        return;
      }
    }
    this.waiting = true;

    of(this.isValidAddress(this.dto.networkId, this.emissionAddress))
      .pipe(
        filter((isNetworkAddress: boolean | any) => {
          if (!isNetworkAddress) {
            this.showWarningModal(this.translate.instant('addressIsNotValid'));
          }
          if (isNetworkAddress === 'not Near address') {
            return this.showWarningModal(this.translate.instant('youWantAddEvmWalletAddress'));
          }
          return isNetworkAddress;
        }),
        switchMapTo(
          this.addressBookServiceProxy.isAddressBookEntryExists(
            this.emissionAddress,
            this.dto.networkId
          )
        ),
        filter((isExist) => {
          if (isExist) {
            this.showWarningModal(this.translate.instant('addressAlreadyUsedByAnotherUser'));
          }
          return !isExist;
        }),
        switchMap(() => {
          const data: AddressBookRequestLiteDTO =
            new AddressBookRequestLiteDTO();
          data.networkId = this.dto.networkId;
          data.emissionAddress = this.emissionAddress;
          data.userWallet = this.dto.userWallet;
          return this.addressBookServiceProxy.addChangeRequest(data);
        }),
        catchError((error: any) => {
          this.waiting = false;
          this.processServiceError(error);
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.waiting = false;
        this.showInfoModal(this.translate.instant('saved'));
        location.reload();
      });
  }

  processServiceError(error: any) {
    const message = error.response ? JSON.parse(error.response).message : null;
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    } else if (message === 'KYC should be confirmed') {
      this.router.navigate(['/kyc']);
    } else {
      console.error(error);
      this.showErrorModal(message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(['/login']);
  }

  private isValidAddress(networkId: number, address: string): boolean | any{
    if (this.evmNetworks.includes(networkId)) {
      return this.web3Service.web3.utils.isAddress(address);
    } else if (NetworkType.Solana == networkId) {
      return this.solanaService.isValidAddress(address);
    } else if (NetworkType.Elrond == networkId) {
      return address.startsWith('erd1') && address.length == 62;
    } else if (NetworkType.Polkadot === networkId) {
      return address.startsWith('1') && (address.length === 47 || address.length === 48);
    } else if (NetworkType.Cosmos === networkId) {
      return address.startsWith('cosmos1') && address.length === 45;
    } else if (NetworkType.Flow === networkId) {
      return address.startsWith('0x') && address.length === 18;
    } else if (NetworkType.Tezos === networkId) {
      if (address.toLowerCase().startsWith('tz') && address.length === 36) {
        return true;
      }else {
        return address.toLowerCase().startsWith('kt') && address.length === 36;
      }
    } else if (NetworkType.Cardano === networkId) {
      if (address.startsWith('addr1') && address.length === 58) {
        return true;
      }else if (address.toLowerCase().startsWith('ae2tdp') && address.length === 59) {
        return true;
      }else if (address.startsWith('stake1') && address.length === 59) {
        return true;
      }else {
        return address.toLowerCase().startsWith('ddzffzc') && address.length === 104;
      }
      } else if (NetworkType.Bitcoin === networkId) {
      if (address.startsWith('1') && address.length === 34) {
        return true
      } else if (address.startsWith('3') && address.length === 34) {
        return true
      }
      else if (address.startsWith('bc1q') && address.length === 42) {
        return true
      }
      else {
        return address.startsWith('bc1p') && address.length === 62;
      }
    } else if (NetworkType.TON === networkId) {
      if (address.startsWith('EQ') && address.length === 48) {
        return true
      } else {
        return address.startsWith('UQ') && address.length === 48;
      }
    } else if (NetworkType.Astar === networkId) {
    if (address.length === 47) {
      return true
    } else {
    return address.startsWith('0x') && address.length === 42;
    }
    } else if (NetworkType.Sui === networkId) {
      return address.startsWith('0x') && address.length === 66;
    } else if (NetworkType.Aptos === networkId) {
      return address.startsWith('0x') && address.length === 66;
    } else if (NetworkType.Near === networkId && address.startsWith('0x') && address.length === 42) {
      return this.translate.instant('notNearAddress');
    } else if (NetworkType.Injective === networkId) {
      return address.startsWith('inj1') && address.length === 42;
    }
    return true;
  }
}
