
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserSessionProvider } from 'src/shared/user-session-provider';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private readonly userSessionProvider: UserSessionProvider, private readonly router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(!this.userSessionProvider.authToken){
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
