export const environment = {
  production: true,
  //eth: {
  //  blpAddress: '0x2065b4797b746b42c7fda6f61804690e25706792',
  //  dealCreatorAddress: '0xe9e8f70d273b5942a5413C2Ba997438B7F116E03',
  //  lockerAddress: '0x0fBdc8647D7967bf8f26D574Fb28aBA5FFa95596',
  //  dealLockupsAddress: '0xA74B8c96044D152f25a237A0dc4A546fab37C7e3'
  //},
  bsc: {
    blpAddress: '0xE652AF898ECB3a4666EedBd2415B2c2De300BF52',
    dealCreatorAddress: '0x7dA548122a42b0bE18f2de4497676AC84cFc1CD6',
    dealCollectWalletCreatorAddress:
      '0xBc4EDfe0615A5FaF68485BE8e055aF1290A2caA2',
    lockerAddress: '0x6F8dF389c8a647a572F563d5c5Fd2A5EF259beB2',
    dealLockupsAddress: '0xfe8E809532B2FFD62Cb093F988a12859dF8A3238',
    vestingSeedAddress: '0xd6a27a88e52ddd7d92dda94be08013ccc1084f30',
    vestingStrategicAddress: '0xaeeca8a94298769f5afb33f21909255ad5888e1d',
    vestingPrivateAddress: '0xcf439554b7b5e8bfff932b9c3454d0d92627700c',
    vestingBountyAddress: '0xa620998D6A98E8a5a3a2879ba9468C0dE32CfAc1',
    stakingPoolMasterAddress: '0x8e6f3F352A961E4E079573EF985823D3cF5bc447',
    pancakeRouterAddress: '0x354bc1cFe9959b96beD409859a726263C6C56C81',
    oldLockerAddress: '0x74f63ABaa4300c0619122a61e4A81406511f358d',
    tierCalculatorAddress: '0xFA4e99034B73A70E5Bbc6c31eD05F7752d1B2239',
    stackingAddress: '0xf2568244e4580f790387c18adc0fa5c14b15b91f',
    newStackingAddress: '0xf2568244e4580f790387c18adc0fa5c14b15b91f',
    refRewardsAddress: '0xc50314c4147498CacCaD2c3cBf0542F7175eF7c6',
    bonusAddress: '0x53bd060227c4499c2c3d1575b2519b5b9e9bade2',
    networkTypes: [
      { id: 1, label: 'ETH' },
      { id: 56, label: 'BSC' },
      { id: 137, label: 'Polygon' },
      { id: -100, label: 'Solana' },
      { id: -200, label: 'Tezos'},
      { id: 97, label: 'BSC Testnet' },
      { id: 42, label: 'Kovan' },
      { id: 80001, label: 'Polygon Testnet' },
      { id: 43114, label: 'Avalanche' },
      { id: 250, label: 'Fantom' },
      { id: 321, label: 'KCC'},
      { id: -9999100, label: 'Elrond' },
      { id: -9999101, label: 'Cardano' },
      { id: -9999102, label: 'Other' },
      { id: -9999103, label: 'Polkadot' },
      { id: -9999104, label: 'Cosmos' },
      { id: -9999105, label: 'Flow' },
      { id: -9999106, label: 'Near' },
      { id: 344435, label: 'Skale'},
      { id: 888, label: 'Wanchain'},
      { id: 1666600000, label: 'Harmony'},
      { id: 66, label: 'OKC'},
      { id: 42161, label: 'Arbitrum'},
      { id: -9999107, label: 'Sui'},
      { id: -9999108, label: 'Bitcoin'},
      { id: -9999109, label: 'Aptos'},
      { id: 10, label: 'Optimism'},
      { id: 8453, label: 'Base'},
      { id: 1284, label: 'Moonbeam'},
      { id: 592, label: 'Astar'},
      { id: 1890, label: 'Lightlink'},
      { id: -9999110, label: 'TON'},
      { id: 81457, label: 'Blast'},
      { id: -9999111, label: 'Injective'},
    ],
  },
  remoteServiceBaseUrl: 'https://blp-clientapi-test.bullperks.com',
  authServiceBaseUrl: 'https://bullauth.gpkit.link',
  // remoteServiceBaseUrl: 'http://localhost:9995',
  // authServiceBaseUrl: 'https://localhost:7255',
  defaultChainId: 97,
  supportedChains: [56, 97],
  captchaSiteKey: '6LfilzUbAAAAAAkSXL_4CUOu9hS1Mkf4TAozctSY',
  defaultClaimingUrl: "https://bullclaimer.com",
  referralProgramVideoUrl: "https://youtu.be/av2AWWNVjhg",
};
