import { Pipe, PipeTransform } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: "YesNo"
})
export class YesNoPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}
    transform(value: any): string {
        if (value) {
            return this.translate.instant('yes');
        }
        return this.translate.instant('no');
    }
}
