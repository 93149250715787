<section class="deals-section">
  <mat-tab-group mat-align-tabs="start" class="fullpage-tabs" disableRipple (selectedTabChange)="tabChanged($event)">
    <mat-tab [label]="group.label" *ngFor="let group of dealGroups">
      <div class="p-40">
        <mat-spinner *ngIf="group.loading | async" class="__loading" diameter="40"></mat-spinner>
        <div class="card not-found" *ngIf="group.nothingFound | async">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{'noDealsInProgressInPortfolio' | translate: {groupLabel: group.label} }}</div>
        </div>
        <div class="card-list">
          <div class="deal-card card cursor-pointer" *ngFor="let item of group.results | async; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>
