import { Pipe, PipeTransform } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: "nameTierPipe"
})
export class NameTierPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}
  transform(index: number): string {
    if (index == -1)
      return this.translate.instant('none');
    if (index == 0)
      return this.translate.instant('copperBull');
    if (index == 1)
      return this.translate.instant('bronzeBull');
    if (index == 2)
      return this.translate.instant('silverBull');
    if (index == 3)
      return this.translate.instant('goldenBull');
    if (index == 4)
      return this.translate.instant('titaniumBull');
    if (index == 5)
      return this.translate.instant('platinumBull');
    return this.translate.instant('none');
  }
}
