const STAKE_MASTER = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newBurnPercent',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'divider',
        type: 'uint256',
      },
    ],
    name: 'BurnPercentUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newFeeAmount',
        type: 'uint256',
      },
    ],
    name: 'FeeAmountUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'newFeeWallet',
        type: 'address',
      },
    ],
    name: 'FeeWalletUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'admin',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'permission',
        type: 'bool',
      },
    ],
    name: 'SetAdminPermission',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'profitSharingBP',
        type: 'uint256',
      },
    ],
    name: 'SetDefaultProfitSharingBP',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'maxDepositFeeBP',
        type: 'uint256',
      },
    ],
    name: 'SetMaxDepositFeeBP',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'stakingPool',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'profitSharingBP',
        type: 'uint256',
      },
    ],
    name: 'SetProfitSharingBP',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'proxyAdmin',
        type: 'address',
      },
    ],
    name: 'SetProxyAdmin',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'penaltyPoolImpl',
        type: 'address',
      },
    ],
    name: 'SetStakePenaltyPoolImpl',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'poolImpl',
        type: 'address',
      },
    ],
    name: 'SetStakePoolImpl',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'tierCalculator',
        type: 'address',
      },
    ],
    name: 'SetTierCalculator',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'pool',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'stakingToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'poolToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'startTime',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'finishTime',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'poolTokenAmount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'hasWhitelisting',
        type: 'bool',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'depositFeeBP',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'feeTo',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'profitSharing',
        type: 'uint256',
      },
    ],
    name: 'StakingPenaltyPoolCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'pool',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'stakingToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'poolToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'startTime',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'finishTime',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'poolTokenAmount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'hasWhitelisting',
        type: 'bool',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'depositFeeBP',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'feeTo',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'profitSharing',
        type: 'uint256',
      },
    ],
    name: 'StakingPoolCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'newFeeToken',
        type: 'address',
      },
    ],
    name: 'TokenFeeUpdated',
    type: 'event',
  },
  {
    inputs: [],
    name: 'MAX_BPS',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'burnPercent',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'defaultProfitSharingBP',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'divider',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'feeAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'feeToken',
    outputs: [
      {
        internalType: 'contract ERC20BurnableUpgradeable',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'feeWallet',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'isAdmin',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxDepositFeeBP',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'penaltyPoolImpl',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'penaltyPoolWL',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'poolImpl',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'profitSharingBP',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'proxyAdmin',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_user', type: 'address' },
      { internalType: 'bool', name: '_permission', type: 'bool' },
    ],
    name: 'setAdminPermission',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'tierCalculator',
    outputs: [
      { internalType: 'contract ITierCalculator', name: '', type: 'address' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract ERC20BurnableUpgradeable',
        name: '_feeToken',
        type: 'address',
      },
      { internalType: 'address payable', name: '_feeWallet', type: 'address' },
      { internalType: 'uint256', name: '_feeAmount', type: 'uint256' },
      { internalType: 'uint256', name: '_burnPercent', type: 'uint256' },
      {
        internalType: 'uint256',
        name: '_defaultProfitSharingBP',
        type: 'uint256',
      },
      { internalType: 'address', name: '_penaltyPoolImpl', type: 'address' },
      { internalType: 'address', name: '_poolImpl', type: 'address' },
      { internalType: 'uint256', name: '_maxDepositFeeBP', type: 'uint256' },
      { internalType: 'address', name: '_proxyAdmin', type: 'address' },
      {
        internalType: 'contract ITierCalculator',
        name: '_tierCalculator',
        type: 'address',
      },
    ],
    name: '__StakeMaster_init',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_newFeeToken', type: 'address' },
    ],
    name: 'setFeeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_newFeeAmount', type: 'uint256' },
    ],
    name: 'setFeeAmount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address payable',
        name: '_newFeeWallet',
        type: 'address',
      },
    ],
    name: 'setFeeWallet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_newBurnPercent', type: 'uint256' },
      { internalType: 'uint256', name: '_newDivider', type: 'uint256' },
    ],
    name: 'setBurnPercent',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_stakingPool', type: 'address' },
      { internalType: 'uint256', name: '_profitSharingBP', type: 'uint256' },
    ],
    name: 'setProfitSharingBP',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_defaultProfitSharingBP',
        type: 'uint256',
      },
    ],
    name: 'setDefaultProfitSharingBP',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_penaltyPoolImpl', type: 'address' },
    ],
    name: 'setPenaltyPoolImpl',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_poolImpl', type: 'address' }],
    name: 'setPoolImpl',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_maxDepositFeeBP', type: 'uint256' },
    ],
    name: 'setMaxDepositFeeBP',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_proxyAdmin', type: 'address' }],
    name: 'setProxyAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_tierCalculator', type: 'address' },
    ],
    name: 'setTierCalculator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
    name: 'resetStartOnce',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
    name: 'getStartByUpdaters',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20Upgradeable',
        name: '_stakingToken',
        type: 'address',
      },
      {
        internalType: 'contract IERC20Upgradeable',
        name: '_poolToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_startTime', type: 'uint256' },
      { internalType: 'uint256', name: '_finishTime', type: 'uint256' },
      { internalType: 'uint256', name: '_poolTokenAmount', type: 'uint256' },
      { internalType: 'bool', name: '_hasWhitelisting', type: 'bool' },
      { internalType: 'uint256', name: '_depositFeeBP', type: 'uint256' },
      { internalType: 'address', name: '_feeTo', type: 'address' },
    ],
    name: 'createStakingPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20Upgradeable',
        name: '_stakingToken',
        type: 'address',
      },
      {
        internalType: 'contract IERC20Upgradeable',
        name: '_poolToken',
        type: 'address',
      },
      { internalType: 'uint256', name: '_startTime', type: 'uint256' },
      { internalType: 'uint256', name: '_finishTime', type: 'uint256' },
      { internalType: 'uint256', name: '_poolTokenAmount', type: 'uint256' },
      { internalType: 'bool', name: '_hasWhitelisting', type: 'bool' },
      { internalType: 'uint256', name: '_depositFeeBP', type: 'uint256' },
      { internalType: 'address', name: '_feeTo', type: 'address' },
    ],
    name: 'createStakingPenaltyPool',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'version',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  },
] as const;

export default STAKE_MASTER;
