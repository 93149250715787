import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface IDlgContractProcessingData {
  inputAmount: string | number;
  fromCurrensySymbol: string;
  outputAmount: string | number;
  toCurrensySymbol: string;
  //TODO: move to strong type
  fromNetwork: any;
  toNetwork: any;
}

@Component({
  selector: 'dlg-contract-processing',
  templateUrl: './dlg-contract-processing.component.html',
  styleUrls: ['./dlg-contract-processing.component.scss']
})
export class DlgContractProcessingComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DlgContractProcessingComponent>,

    @Inject(MAT_DIALOG_DATA) public data: IDlgContractProcessingData
  ) {}

  ngOnInit(): void {}
}
