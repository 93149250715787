import { Location } from '@angular/common';
import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import {ActivatedRoute, Router} from '@angular/router';
import {
  EquityDealDTO,
  EquityDealServiceProxy,
  IEquityDealDTO, UserDTO,
  UsersServiceProxy
} from '../../service-proxies/service-proxies';
import { Web3Service } from '../../shared/web3-service';
import { EventBus } from '../../shared/event-bus';
import { UserSessionProvider } from '../../shared/user-session-provider';
import {TranslateService} from "@ngx-translate/core";
import swal from "sweetalert2";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'equity-deal/:id',
  templateUrl: './equity-deal.component.html',
  styleUrls: ['./equity-deal.component.scss']
})
export class EquityDealComponent extends ComponentBase implements OnInit, AfterViewInit {
  constructor(
    private web3: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: EquityDealServiceProxy,
    private activatedRoute: ActivatedRoute,
    private eventBus: EventBus,
    private elementRef: ElementRef,
    public translate: TranslateService,
    private usersService: UsersServiceProxy,
    private router: Router,
    private location: Location,
  ) {
    super();
  }

  updateTimeTimerId: NodeJS.Timeout;
  timerViewDays: number;
  timerViewHours: number;
  timerViewMin: number;
  timerViewSec: number;

  timerViewDaysWire: number;
  timerViewHoursWire: number;
  timerViewMinWire: number;
  timerViewSecWire: number;

  guid: string;
  waiting: boolean = true;
  account: string = '';
  userTierIndex: number | null = null;
  deal: IEquityDealDTO = new EquityDealDTO();

  faqItems: any = [];

  userInfo: UserDTO;
  kycConfirmed: boolean = false;
  @ViewChild('faqVideoElement') faqVideoElement: ElementRef;
  public get isFinished(): boolean {
    return this.deal?.deadline ? Date.now() > Date.parse(this.deal.deadline.toString()) : true;
  }

  public get isFinishedWire(): boolean {
    return this.deal?.wireDeadline ? Date.now() > Date.parse(this.deal.wireDeadline.toString()) : true;
  }

  /*public get showInvestButton(): boolean {
    return this.userTierIndex >= 3
      && (this.deal.deadline && this.deal.wireDeadline
        ? !this.isFinishedWire
        : (!this.deal.deadline || !this.isFinished) && (!this.deal.wireDeadline || !this.isFinishedWire));
  }*/

  public get showInvestButton(): boolean {
    return this.userSessionProvider.isAuthorized && this.userTierIndex >= 3
  }

  public showWarningModal() {
    swal.fire({
      text: this.translate.instant('youMustBeGoldenBullOrAbove'),
      icon: 'warning',
      confirmButtonText: this.translate.instant('Upgrade')
    }).then((result) => {
      if (result.isConfirmed) this.router.navigate(['/swap-bridge']);
    })
  }

  async ngOnInit() {
    if (this.userSessionProvider.isAuthorized) {
      this.usersService.getMe()
        .subscribe(
          result => {
            this.userInfo = result;
            this.kycConfirmed = result.kycConfirmed;
            if(!this.kycConfirmed) {
              this.navigateToKYC();
            }
          },
          error => {
            this.processServiceError(error);
          }
        );

    }
    await this.web3.initWeb3();

    this.eventBus.loginEvent.subscribe(result => {
      this.eventLogin(result);
    });

    this.web3.calcUserTierIndex(this.userSessionProvider.linkedWallet).then((result) => {
      if (this.userTierIndex === null) {
        this.userTierIndex = result;
      }
    })

    this.guid = this.activatedRoute.snapshot.params.guid;

    if (Number(this.guid) >= 2) {
      await this.router.navigate(['/']);
    }

    if(this.guid == '1' && environment.production){
      const requiredGuid = '65d408c8-92e6-ed7e-68e1-84ad4904036a';
      this.location.go(`/equity-deal/${requiredGuid}`);
      this.guid = requiredGuid;
    }
    this.dealService.getbyGuid(this.guid).subscribe(result => {
      this.waiting = false;
      this.deal = result;
      if (result.faq) {
        this.faqItems = this.parseFaq(result.faq);
      }
    },
    error => {
      this.showErrorModal(JSON.parse(error.response).message);
    });

    this.updateTimeTimerId = setInterval(() => {
      let diffStart = !this.isFinished ? Date.parse(this.deal?.deadline.toString()) - Date.now() : 0;
      diffStart = Math.floor(diffStart / 1000);

      this.timerViewDays = diffStart > 0 ? Math.floor(diffStart / (3600 * 24)) : 0;
      this.timerViewHours = diffStart > 0 ? Math.floor(diffStart % (3600 * 24) / 3600) : 0;
      this.timerViewMin = diffStart > 0 ? Math.floor(diffStart % 3600 / 60) : 0;
      this.timerViewSec = diffStart > 0 ? Math.floor(diffStart % 60) : 0;

    }, 1000);

    setInterval(() => {
      const faqVideo = this.faqVideoElement.nativeElement;
      const faqVideoWidth = faqVideo.offsetWidth;
      faqVideo.style.setProperty('--faq-width', `${faqVideoWidth}px`)

      let diffStart = !this.isFinishedWire ? Date.parse(this.deal?.wireDeadline.toString()) - Date.now() : 0;
      diffStart = Math.floor(diffStart / 1000);

      this.timerViewDaysWire = diffStart > 0 ? Math.floor(diffStart / (3600 * 24)) : 0;
      this.timerViewHoursWire = diffStart > 0 ? Math.floor(diffStart % (3600 * 24) / 3600) : 0;
      this.timerViewMinWire = diffStart > 0 ? Math.floor(diffStart % 3600 / 60) : 0;
      this.timerViewSecWire = diffStart > 0 ? Math.floor(diffStart % 60) : 0;
    }, 1000);
  }

  ngAfterViewInit() {
    const element = this.elementRef.nativeElement.querySelector('#summary');
    const elementWidth = element.offsetWidth;
    element.style.setProperty('--element-width', `${elementWidth}px`);
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  navigateToKYC(): void {
    this.router.navigate(["/kyc"]);
  }
  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  async eventLogin(username: string) {
    if (this.account != username) {
      this.account = username;
    }

    this.web3.calcUserTierIndex(this.userSessionProvider.linkedWallet).then((result) => {
      this.userTierIndex = result;
    })
  }

  addToGoogleCalendar() {
    const startDate = (new Date()).toISOString().replace(/-|:|\.\d\d\d/g, '');
    const finishDate = this.deal.deadline.toString().replace(/-|:|\.\d\d\d/g, '');
    const url = new URL('https://www.google.com/calendar/render');
    const eventInfo = 'Deal details: <a href="https://app.bullperks.com/equity-deal/' + this.deal.id + '">' +
        'https://app.bullperks.com/equity-deal/' + this.deal.id + '</a>';
    url.searchParams.append('action', 'TEMPLATE');
    url.searchParams.append('text', this.deal.name + ' Equity Deal on BullPerks');
    url.searchParams.append('dates', startDate + '/' + finishDate);
    url.searchParams.append('details', eventInfo);
    url.searchParams.append('sf', 'true');
    url.searchParams.append('output', 'xml');

    window.open(url.toString(), '_blank').focus();
  }

  parseFaq(html: string): any[] {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const matches = Array.from(doc.querySelectorAll('p, figure')).filter(x => x.innerHTML != '<br>').map(x => x);
    return matches.reduce(function (result, option, index) {
      if (option.innerHTML.includes('<strong')) {
        result.push({ title: option, text: matches[index + 1].nodeName === 'FIGURE' ? matches[index + 2] : matches[index + 1], video: matches[index + 1].nodeName === 'FIGURE' ? matches[index + 1] : ''});
      }
      return result;
    }, []);
  }
}
