<div class="modal-header">
  <h5 class="modal-title">{{'disclaimer' | translate}}</h5>
</div>

<div *ngIf="!disclaimer" [innerHTML]="disclaimerText | safeHtml" class="disclaimer-text" #disclaimerTextBlock></div>

<iframe *ngIf="disclaimer"
        [src]="disclaimerUrl"
>{{'loading' | translate}}</iframe>

<div class="modal-body ">
  <div class="buttons">
    <button mat-raised-button color="primary" class="w-100" [mat-dialog-close]="true" [disabled]="!(isButtonEnabled | async)">
      {{'accept' | translate}}
    </button>
    <button mat-raised-button color="warn" class="w-100" [mat-dialog-close]="false">
      {{'reject' | translate}}
    </button>
  </div>
</div>
