<header>
  <button mat-button class="__close" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
</header>
<section>
  <div class='__networks'>
    <div *ngIf="data?.fromNetwork">
      <img [src]="data.fromNetwork.imgModal" style="height: 50px">
      <p style="margin:0">
        {{data.fromNetwork.name}}
      </p>
    </div>
    <div class='__spinner'>
      <mat-spinner diameter='50'></mat-spinner>
      <svg>
        <use xlink:href="#arrow"></use>
      </svg>
    </div>
    <div *ngIf="data?.toNetwork">
      <img [src]="data.toNetwork.imgModal" style="height: 50px">
      <p style="margin:0">
        {{data.toNetwork.name}}
      </p>
    </div>
  </div>
  <br />
  <div>
    {{'waitingForConfirmation' | translate}}
  </div>
  <!--<div *ngIf="!(data.inputAmount && data.outputAmount)">
    Swaping: {{data.fromCurrensySymbol}} for {{data.toCurrensySymbol}}
  </div>-->

  <div *ngIf="data?.inputAmount && data?.outputAmount">
    {{'swapping' | translate}}: {{data.inputAmount}} {{data.fromCurrensySymbol}}
    {{'for' | translate}} {{data.outputAmount}} {{data.toCurrensySymbol}}
  </div>
  <div>
    {{'confirmThisTransactionInYourWallet' | translate}}
  </div>
</section>
