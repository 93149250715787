import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { AlertService } from '../shared-dlg.module';
import { AccountServiceProxy, ConfirmEmailDTO } from '../../service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { EventBus } from '../../shared/event-bus';
import { UserSessionProvider } from '../../shared/user-session-provider';

@Component({
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailPageComponent extends ComponentBase implements OnInit {
  constructor(
    private userSessionProvider: UserSessionProvider,
    private _dialog: MatDialog,
    private _alertSrv: AlertService,
    private web3Service: Web3Service,
    private accountService: AccountServiceProxy,
    private route: ActivatedRoute,
    private router: Router,
    private eventBus: EventBus
  ) {
    super();
    const refValue = this.route.snapshot.queryParams['referrer'];
    this.referrer = refValue;
  }

  confirmationKey: string;
  waiting: boolean = false;
  account: string = '';
  step: number = 1;
  private referrer: string = undefined;

  async ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        this.confirmationKey = params['token'];
        this.checkToken();
      });

    await this.web3Service.initWeb3();
       this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });
  }


  async eventLogin(username: string) {
    console.log('eventLogin');
    console.log(username);
    this.account = username;
    this.step = 2;
  }

  checkToken(): void {
    if (!this.confirmationKey)
      this.router.navigate(["/login"]);
  }
  async unlockWallet() {
    const dialogRef = this._dialog.open(DlgUnlockWalletComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  async confirmSignUp() {
    if (!this.confirmationKey) {
      this._alertSrv.show("Error token", 'error');
      //this.showErrorModal("Error token!");
      return;
    }
    if (!this.account) {
      this.unlockWallet();
      return;
    }
    var data: ConfirmEmailDTO = new ConfirmEmailDTO();
    data.platform = 'BullPerks';
    data.token = this.confirmationKey;
    data.signature = await this.web3Service.PersonalSign(this.confirmationKey, this.account);
    data.referrer = this.referrer;
    if (!data.signature) {
      console.log("Not signed!");
      return;
    }
    data.ethAddress = this.account;
    this.waiting = true;

    this.accountService.confirmEmail(data)
      .subscribe(
        result => {
          this.waiting = false;
          this.userSessionProvider.startAuth(result.userName, result.accessToken);
          this.router.navigate(["/"]);
        },
        error => {
          this.waiting = false;
          this._alertSrv.show(JSON.parse(error.response).message, 'error');
          //this.showErrorModal(JSON.parse(error.response).message);
        }
      );
  }
}
