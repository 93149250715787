import { Component} from '@angular/core';
import { UltradeComponent } from '@ultrade/angular-embed';


@Component({
    templateUrl: './ultrade-widget-component.html',
    styleUrls: ['./ultrade-widget-component.scss'],
})
export class UltradeWidgetComponent {
    

}   