import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { UserSessionProvider } from '../../shared/user-session-provider';
import {EquityDealDTO, EquityDealServiceProxy, UserDTO, UsersServiceProxy} from '../../service-proxies/service-proxies';
import { Router } from '@angular/router';
import { EventBus } from '../../shared/event-bus';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'equity-deals',
  templateUrl: './equity-deals.component.html',
  styleUrls: ['./equity-deals.component.scss'],
})

export class EquityDealsComponent extends ComponentBase implements OnInit {
  constructor(
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: EquityDealServiceProxy,
    private eventBus: EventBus,
    private router: Router,
    public translate: TranslateService,
    private usersService: UsersServiceProxy,
  ) {
    super();
  }

  public deals: Array<EquityDealDTO> = [];

  waiting: boolean = false;
  account: string = '';
  waitingDeals: boolean = true;
  userTierIndex: number | null = null;
  userInfo: UserDTO;
  kycConfirmed: boolean = false;

  async ngOnInit() {
    if (this.userSessionProvider.isAuthorized) {
      this.usersService.getMe()
        .subscribe(
          result => {
            this.userInfo = result;
            this.kycConfirmed = result.kycConfirmed;
            if(!this.kycConfirmed) {
              this.navigateToKYC();
            }
          },
          error => {
            this.processServiceError(error);
          }
        );

    }
    await this.web3Service.initWeb3();

    this.eventBus.loginEvent.subscribe(result => {
      this.eventLogin(result);
    });

    this.web3Service.calcUserTierIndex(this.userSessionProvider.linkedWallet).then((result) => {
      if (this.userTierIndex === null) {
        this.userTierIndex = result;
      }
    })

    this.dealService.getAll(this.web3Service.chainIdNumber)
      .subscribe(result => {
        result.sort((s1,s2)=>{return s2.id - s1.id});
        this.deals = result;
        this.waitingDeals = false;
      },
        error => {
          this.processServiceError(error);
        });
  }

  async eventLogin(username: string) {
    if (this.account != username) {
      this.account = username;
    }

    this.web3Service.calcUserTierIndex(this.userSessionProvider.linkedWallet).then((result) => {
      this.userTierIndex = result;
    })
  }

  navigateToKYC(): void {
    this.router.navigate(["/kyc"]);
  }
  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
}
