<header class="mob-menu-header bg-dark">
  <button
    class="mob-menu-toggle"
    type="button"
    (click)="toggleMenu()">
    <b></b>
  </button>

  <div class="mob-menu-header__title" [ngSwitch]="routerPath">
    <span *ngIf="routerPath=='/'">{{'home' | translate}}</span>
    <span *ngIf="routerPath=='/address-book'">{{'addressBook' | translate}}</span>
    <span *ngIf="routerPath=='/kyc'">{{'kyc' | translate}}</span>
    <span *ngIf="routerPath=='/deals'">{{'deals' | translate}}</span>
    <span *ngIf="routerPath=='/deal-detail'">{{'dealDetails' | translate}}</span>
    <span *ngIf="routerPath=='/farming'">{{'farmingPools' | translate}}</span>
    <span *ngIf="routerPath=='/staking'">{{'stakingPools' | translate}}</span>
    <span *ngIf="routerPath=='/pool-detail'">{{'poolDetails' | translate}}</span>
    <span *ngIf="routerPath=='/portfolio'">{{'portfolio' | translate}}</span>
    <span *ngSwitchCase="'/login'">{{'login' | translate}}</span>
    <span *ngSwitchCase="'/create-pool'">{{'createPool' | translate}}</span>
    <span *ngSwitchCase="'/claiming-deal-detail'">{{'dealDetails' | translate}}</span>
    <span *ngSwitchCase="'/vesting'">{{'claimTokens' | translate}}</span>
  </div>

  <div class="mobile-logo">
    <a routerLink="/"><img src="../../assets/images/logo-bull.svg" alt="Bull Starter"/></a>
  </div>
</header>
