import { Pipe, PipeTransform } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: "dealTypeName"
})
export class DealTypeNamePipe implements PipeTransform {
  constructor(public translate: TranslateService) {}
  transform(value: number): string {
    if (value == 0)
      return this.translate.instant('vc')
    else if (value == 1)
      return this.translate.instant('ido')
    else if (value == 2)
      return this.translate.instant('ino')
    else if (value == 3)
      return this.translate.instant('equity')
    return value.toString();
  }
}
