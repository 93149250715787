import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import {UsersServiceProxy} from "../service-proxies/service-proxies";

@Injectable({
  providedIn: 'root',
})
export class KycGuard {
  constructor(private readonly userSessionProvider: UserSessionProvider, private readonly router: Router, private usersService: UsersServiceProxy) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userSessionProvider.isAuthorized) {
      this.usersService.getMe()
        .subscribe(
          result => {
            if(result.kycConfirmed) this.router.navigate(["/"])
            return false
          }
        );
    }
    return true
  }
}
