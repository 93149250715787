import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { Web3Service } from '../../shared/web3-service';
import networks from '../networks.data';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { AlertService } from '../shared-dlg.module';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from "@angular/router";
import {environment} from "../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import { NotificationDTO, NotificationServiceProxy } from 'src/service-proxies/service-proxies';
import { EventBus } from 'src/shared/event-bus';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './app-dashboard-header.component.html',
  styleUrls: ['./app-dashboard-header.component.scss']
})
export class AppDashboardHeaderComponent implements OnInit {
  isMenu = false;
  @HostBinding('attr.is-menu') get _isMenu() {
    if (this.isMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return this.isMenu;
  }

  @HostListener('wheel', ['$event'])
  onScroll($event: MouseEvent) {
    if (this.isMenu) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  constructor(
    public web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private _alertSrv: AlertService,
    private _dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private eventBus: EventBus,
    public notificationService: NotificationServiceProxy
  ) { }

  public routerPath: string;
  public routerPathAddress: string;
  public vestingSeedAddress: string = environment.bsc.vestingSeedAddress;
  public vestingStrategicAddress: string = environment.bsc.vestingStrategicAddress;
  public vestingPrivateAddress: string = environment.bsc.vestingPrivateAddress;
  public vestingBountyAddress: string = environment.bsc.vestingBountyAddress;
  public availableNetworks:any[];
  public networkData: any[];
  public languages: string[] = ['en', 'es', 'tr', 'ru', 'hi', 'zh', 'ja', 'vi'];
  public notifications: NotificationDTO[] = [];
  updateNotificationsTimerId: NodeJS.Timeout;

  public get authenticated() {
    return this.userSessionProvider.authToken;
  }

  public get notificationsStatus() {
    if (this.notifications?.length > 0) {
      return `${this.unreadNotificationsCount > 0 ? this.unreadNotificationsCount : this.translate.instant('no')} ${this.unreadNotificationsCount != 1 ? this.translate.instant('newNotifications') : this.translate.instant('newNotification')}`;
    }
    else {
      return this.translate.instant('noNotifications');
    }
  }

  public get unreadNotificationsCount() {
    return this.notifications?.filter(x => x.read === false).length ?? 0;
  }

  ngOnInit(): void {
    this.router.events.subscribe(value => {
      this.routerPath = this.router.url === "/" ? "/" : this.router.url.split('/')[1];
      let urlParts = this.router.url.split('/');
      if (urlParts.length >= 3 || this.router.url.includes('?')) {
        this.routerPath = this.routerPath.includes('?') ? this.routerPath.split('?')[0] : this.routerPath;
        this.routerPathAddress = urlParts[2];
      }
    });

    this.availableNetworks = environment.supportedChains;
    this.networkData = networks;

    if (this.userSessionProvider.linkedWallet) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }
    this.eventBus.loginEvent.subscribe(result => {
      this.eventLogin(result);
    });
  }

  async ngOnDestroy() {
    if (this.updateNotificationsTimerId) {
      clearInterval(this.updateNotificationsTimerId);
    }
  }

  async eventLogin(username: string) {
    if (!this.updateNotificationsTimerId) {
      this.updateNotifications();
      this.updateNotificationsTimerId = setInterval(() => {
        this.updateNotifications();
      }, 60000);
    }
  }

  eventLogout(): void {
    if (this.updateNotificationsTimerId) {
      clearInterval(this.updateNotificationsTimerId);
    }
  }

  public updateNotifications() {
    this.notificationService.getAll()
      .subscribe(result => {
        this.notifications = result;
      },
        error => {
          console.error(error);
        });
  }

  backToPrev() {
    window.history.back();
  }

  public selectLanguage(language: string) {
    this.userSessionProvider.setLang(language);
    this.translate.use(language);
  }

  public getNetworkName(networkId: number): string{
    return this.networkData.find(i=>i.chainId == networkId).name;
  }

  async selectNetworkClick(chainId: number): Promise<boolean> {
    console.log("selectNetworkClick", chainId);
    if (this.web3Service.chainIdNumber == chainId) {
      //The same network
      console.log("same network",this.web3Service.chainIdNumber,  chainId);
      this._dialog.closeAll();
      return false;
    }

    var toNetwork = networks.find((n) => n.chainId == chainId);

    const provider = (window as any).ethereum;
    //If exist metamask and connected account
    //TODO: get account from web3
    if (provider && this.userSessionProvider.linkedWallet) {
      if (toNetwork.networkParams) {
        try {
          // @ts-ignore
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId:toNetwork.networkParams.chainId}],
          });
          return true;
        } catch (switchError: any) {

          if (switchError.code === 4902) {
            try {
              // @ts-ignore
              await provider.request({
                method: 'wallet_addEthereumChain',
                params: [toNetwork.networkParams],
              });
            } catch (addError) {
              console.error(addError);
              return false;
            }
          }
          return false;
        }
      } else {
        this._alertSrv.show(
           this.translate.instant('selectNetworkInYourWallet', { toNetworkName: toNetwork.name }),
          'error'
        );
        return false;
      }
    } else {
      console.log("set chain id", toNetwork.chainId);
      this.userSessionProvider.setChainId(toNetwork.chainId);
      location.reload();
      return false;
    }
  }

  async selectNotification(event: any, notification: NotificationDTO) {
    if (notification.linkURL) {
      window.location.href = notification.linkURL
    }
    else {
      event.stopPropagation();
    }
  }

  async clearNotifications() {
    const ids = this.notifications.map(x => x.id);
    if (ids) {
      this.notificationService.update('clear', ids).subscribe(() => this.updateNotifications());
    }
  }

  async notificationsClosed() {
    const ids = this.notifications.map(x => x.id);
    if (ids) {
      this.notificationService.update('read', ids).subscribe(() => this.updateNotifications() );
    }
  }
}
